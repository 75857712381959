import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './index';
import { environment } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as fromStudent from './student/student.reducer';
import * as fromStudentJournal from './student-journal/student-journal.reducer';
import * as fromRecordings from './recordings/recordings.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BackendEffect } from '../backend/backend.effect';
import * as fromExercisesArchive from './exercises-archive';
import { exercisesArchiveFeatureKey } from './exercises-archive/exercise-archive.selector';
import { AnalyticsEffects } from '../analytics/analytics-effects.service';
import { UserguidingEffectsService } from '../userguiding/userguiding-effects.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([BackendEffect, AnalyticsEffects, UserguidingEffectsService]),

    environment.enableStoreDevTools ? StoreDevtoolsModule.instrument() : [],

    StoreModule.forFeature(fromStudent.studentsFeatureKey, fromStudent.reducer),

    StoreModule.forFeature(fromStudentJournal.studentJournalFeatureKey, fromStudentJournal.reducer),

    StoreModule.forFeature(exercisesArchiveFeatureKey, fromExercisesArchive.reducer, {
      metaReducers: fromExercisesArchive.metaReducers
    }),

    StoreModule.forFeature(fromRecordings.recordingsFeatureKey, fromRecordings.reducer)
  ]
})
export class RootStoreModule {}

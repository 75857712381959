import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {ExerciseArchive} from './index';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ExerciseArchiveFacadeService {
    allDataLoaded$ = this.store.select(ExerciseArchive.hasMorePages).pipe(map(hasMorePages => !hasMorePages));

    exercises$ = this.store.select(ExerciseArchive.selectAll);

    isLoading$ = this.store.select(ExerciseArchive.isLoading);

    constructor(private store: Store) {
    }

    fetchFirstPage(pageSize: number): void {
        this.store.dispatch(ExerciseArchive.clearExercises());
        this.fetchNextPage(pageSize);
    }

    fetchNextPage(pageSize: number): void {
        this.store.dispatch(ExerciseArchive.fetchNextPage({pageSize}));
    }
}

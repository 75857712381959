import { Injectable } from '@angular/core';
import {
  OnCreateAudioRecordingLinkSubscription,
  OnDeleteAudioRecordingLinkSubscription,
  OnUpdateAudioRecordingLinkSubscription,
  SubscriptionResponse
} from './api.service';
import { AuthService } from '../auth/auth.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import API, { graphqlOperation } from '@aws-amplify/api-graphql';
import { Observable as ZenObservable } from 'zen-observable-ts';

@Injectable({ providedIn: 'root' })
export class ApiCustomService {
  constructor(private authService: AuthService) {}

  onCreateAudioRecordingLink$ = this.createObservable<
    OnCreateAudioRecordingLinkSubscription,
    { onCreateAudioRecordingLink: OnCreateAudioRecordingLinkSubscription }
  >(userName => ({
    query: `subscription OnCreateAudioRecordingLink($owner: String!) {
        onCreateAudioRecordingLink(owner: $owner) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          contentType
          owner
        }
      }`,
    variables: { owner: userName }
  })).pipe(map(next => next.onCreateAudioRecordingLink));

  onUpdateAudioRecordingLink$ = this.createObservable<
    OnUpdateAudioRecordingLinkSubscription,
    { onUpdateAudioRecordingLink: OnUpdateAudioRecordingLinkSubscription }
  >(userName => ({
    query: `subscription OnUpdateAudioRecordingLink($owner: String!) {
          onUpdateAudioRecordingLink(owner: $owner) {
            __typename
            id
            createdOn
            updatedOn
            name
            size
            duration
            uploadDone
            url
            owner
          }
        }`,
    variables: { owner: userName }
  })).pipe(map(next => next.onUpdateAudioRecordingLink));

  // onUpdateAudioRecordingLinkListener = this.authService.currentUser$.pipe(
  //   map(next => next.username),
  //   filter(next => !!next),
  //   switchMap(
  //     userName =>
  //       new Observable<OnUpdateAudioRecordingLinkSubscription>(subscriber => {
  //         const subscription = (API.graphql(
  //           graphqlOperation(
  //             `subscription OnUpdateAudioRecordingLink($owner: String!) {
  //       onUpdateAudioRecordingLink(owner: $owner) {
  //         __typename
  //         id
  //         createdOn
  //         updatedOn
  //         name
  //         size
  //         duration
  //         uploadDone
  //         url
  //         owner
  //       }
  //     }`,
  //             { owner: userName }
  //           )
  //         ) as ZenObservable<
  //           SubscriptionResponse<{ onUpdateAudioRecordingLink: OnUpdateAudioRecordingLinkSubscription }>
  //         >).subscribe(next => subscriber.next(next.value.data.onUpdateAudioRecordingLink));
  //         return () => subscription.unsubscribe();
  //       })
  //   )
  // );

  onDeleteAudioRecordingLink$: Observable<OnDeleteAudioRecordingLinkSubscription> = this.createObservable<
    OnDeleteAudioRecordingLinkSubscription,
    { onDeleteAudioRecordingLink: OnDeleteAudioRecordingLinkSubscription }
  >(userName => ({
    query: `subscription OnDeleteAudioRecordingLink($owner: String!) {
        onDeleteAudioRecordingLink(owner: $owner) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          owner
        }
      }`,
    variables: { owner: userName }
  })).pipe(map(next => next.onDeleteAudioRecordingLink));

  private createObservable<R, T>(grapQlQueryFactory: (username: string) => { query: string; variables?: any }): Observable<T> {
    return this.authService.currentUser$.pipe(
      map(next => next.sub),
      filter(next => !!next),
      switchMap(userName => {
        return new Observable<T>(subscriber => {
          const { query, variables } = grapQlQueryFactory(userName);
          const subscription = (API.graphql(graphqlOperation(query, variables)) as ZenObservable<SubscriptionResponse<R>>).subscribe(next =>
            subscriber.next(next.value.data as any)
          );
          return () => subscription.unsubscribe();
        });
      })
    );
  }
}

import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as StudentNotesActions from './student-notes.actions';
import { Notes } from '../../../model/notes';

export interface State extends EntityState<Notes> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Notes> = createEntityAdapter<Notes>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const studentNotesReducer = createReducer(
  initialState,
  on(StudentNotesActions.addStudentNotes, (state, action) => adapter.addOne(action.notes, state)),
  on(StudentNotesActions.addManyStudentNotes, (state, action) => adapter.addMany(action.notes, state)),
  on(StudentNotesActions.clearStudentNotes, state => adapter.removeAll(state))
);

export function reducer(state: State | undefined, action: Action) {
  return studentNotesReducer(state, action);
}

import { createAction, props } from '@ngrx/store';
import { Student } from 'src/app/model/student';

export const addStudent = createAction('[Student/API] Add Student', props<{ student: Student }>());

// export const upsertStudent = createAction('[Student/API] Upsert Student', props<{ student: Student }>());

export const addStudents = createAction('[Student/API] Add Students', props<{ students: Student[] }>());

// export const upsertStudents = createAction('[Student/API] Upsert Students', props<{ students: Student[] }>());

export const updateStudent = createAction('[Student/API] Update Student', props<{ student: Student }>());

// export const updateStudents = createAction('[Student/API] Update Students', props<{ students: Update<Student>[] }>());

export const deleteStudent = createAction('[Student/API] Delete Student', props<{ id: string }>());

// export const deleteStudents = createAction('[Student/API] Delete Students', props<{ ids: string[] }>());

// export const clearStudents = createAction('[Student/API] Clear Students');

// ----- Backend ----
export const fetchStudents = createAction('[Student/API] Fetch Students');

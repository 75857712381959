import { createAction, props } from '@ngrx/store';
import { Exercise } from '../../model/exercise';

const addLoadedExercises = createAction('[Exercise-Archive/API] Add Exercises', props<{ exercises: Exercise[]; nextPageToken?: string }>());

const clearExercises = createAction('[Exercise-Archive/API] Clear Exercises');

// ----- Backend ----
const fetchNextPage = createAction('[Exercise-Archive/API] Fetch Next Page', props<{ pageSize: number }>());

export const ExerciseArchiveActions = {
  addLoadedExercises,
  clearExercises,
  fetchNextPage
};

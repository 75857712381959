import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { BackendTasksService } from './backend-tasks.service';
import { ExerciseArchive } from '../store/exercises-archive';
import { Store } from '@ngrx/store';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ExerciseArchiveBackendTasksService {
  constructor(private backend: BackendService, private tasks: BackendTasksService, private store: Store) {}

  init(): void {
    this.tasks.register(ExerciseArchive.fetchNextPage, action => {
      return this.store.select(ExerciseArchive.nextPageToken).pipe(
        take(1),
        switchMap(nextPageToken => this.backend.listExercises(action.pageSize, nextPageToken)),
        map(result =>
          ExerciseArchive.addLoadedExercises({
            exercises: result.items,
            nextPageToken: result.nextToken
          })
        )
      );
    });
  }
}

import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, pairwise, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { API, Auth, Hub } from 'aws-amplify';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

export const QUERY_PARAM_USERNAME = 'userName';
export const QUERY_PARAM_TOKEN = 'token';

export interface AuthData {
  email: string;
  sub: string;
  name: string;
  username: string;
  trivsToken: string;
  identityId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authEvents$ = new Subject<string>();

  private authData$: Observable<AuthData | undefined> = this.authEvents$.pipe(
    startWith('init'),
    distinctUntilChanged(),
    switchMap(() =>
      combineLatest([Auth.currentAuthenticatedUser({ bypassCache: true }), Auth.currentCredentials()]).pipe(
        map(([user, credentials]) => {
          const authData: AuthData = {
            name: user.attributes.name,
            sub: user.attributes.sub,
            username: user.username,
            email: user.attributes.email,
            trivsToken: user.attributes['custom:trivscoachtoken'],
            identityId: credentials.identityId
          };
          return authData;
        }),
        catchError(error => {
          console.warn(`Caught error while getting current user: ${error}`);
          return of(undefined);
        })
      )
    )
  );
  private authDataReplayed$ = this.authData$.pipe(shareReplay({ refCount: false, bufferSize: 1 }));
  currentUser$: Observable<AuthData> = this.authDataReplayed$.pipe(filter(authData => !!authData));
  authenticated$: Observable<boolean> = this.authDataReplayed$.pipe(map(authData => !!authData));

  constructor(private location: Location, private http: HttpClient, private router: Router) {}

  init(): void {
    Hub.listen('auth', data => {
      this.authEvents$.next(data.payload.event);
    });

    this.authenticated$
      .pipe(
        distinctUntilChanged(),
        pairwise(),
        filter(
          ([last, current]) =>
            // when logged out:
            last && !current
        )
      )
      .subscribe(() => {
        console.log('rerouting after logout');
        this.router.navigate(['/login']);
      });
  }

  async authenticateWithTrivsToken(activatedRoute: ActivatedRouteSnapshot): Promise<boolean> {
    const userName = activatedRoute.queryParams[QUERY_PARAM_USERNAME];
    const token = activatedRoute.queryParams[QUERY_PARAM_TOKEN];

    if (token && userName) {
      return Auth.signOut().then(() =>
        Auth.signIn(userName)
          .then(user => {
            return Auth.sendCustomChallengeAnswer(user, token);
          })
          .then(user => {
            if (!user.getSignInUserSession()) {
              console.warn('Authentication failed. Token did not match.');
              return false;
            } else {
              const url = new URL(window.location.toString());
              url.searchParams.delete(QUERY_PARAM_TOKEN);
              url.searchParams.delete(QUERY_PARAM_USERNAME);
              console.log(url.href);
              window.location.replace(url.href);
              return true;
            }
          })
      );
    } else {
      return false;
    }
  }

  createMagicLink(): Promise<string> {
    return this.currentUser$
      .pipe(
        take(1),
        switchMap(user =>
          from(this.refreshToken(user.email)).pipe(
            tap(() => this.authEvents$.next()),
            switchMap(() => this.authData$),
            filter(authData => !!authData),
            take(1),
            map(authData => {
              const url = new URL(window.location.toString());
              url.searchParams.append(QUERY_PARAM_USERNAME, authData.username);
              url.searchParams.append(QUERY_PARAM_TOKEN, authData.trivsToken);
              return url.toString();
            })
          )
        )
      )
      .toPromise();
  }

  private refreshToken(email: string): Promise<any> {
    return API.endpoint('auth').then(authApi => {
      const url = new URL(authApi + '/send-fresh-token');
      url.searchParams.append('email', email);
      return this.http.post(url.toString(), {}).toPromise();
    });
  }

  sendLoginLink(email: string): Promise<any> {
    return API.endpoint('auth').then(authApi => {
      const url = new URL(authApi + '/send-fresh-token');
      url.searchParams.append('email', email);
      return this.http.post(url.toString(), {}).toPromise();
    });
  }

  async signUp(email: string, name: string): Promise<any> {
    await Auth.signUp({
      username: email,
      password: this.getRandomString(),
      attributes: {
        email,
        name
      }
    });

    await this.sendLoginLink(email);
  }

  private getRandomString() {
    const randomValues = new Uint8Array(30);
    crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join('');
  }
}

const intToHex = (nr: number) => nr.toString(16).padStart(2, '0');

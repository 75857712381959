import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { BackendTasksService } from './backend-tasks.service';
import { concat, from, of } from 'rxjs';
import { ignoreElements, map } from 'rxjs/operators';
import {
  addStudentExerciseSet,
  addStudentExerciseSets,
  clearStudentExerciseSets,
  fetchStudentExerciseSets
} from '../store/student-journal/student-exercise-set/student-exercise-set.actions';
import { ExerciseSet } from '../model/exercise-set';
import {
  addManyStudentNotes,
  addStudentNotes,
  clearStudentNotes,
  fetchStudentNotes
} from '../store/student-journal/student-notes/student-notes.actions';

@Injectable({ providedIn: 'root' })
export class StudentJournalBackendTasksService {
  constructor(private backend: BackendService, private tasks: BackendTasksService) {}

  init(): void {
    this.tasks.register(addStudentExerciseSet, action => {
      const exerciseSet: ExerciseSet = {
        ...action.exerciseSet,
        exercises: action.exerciseSet.exercises.map((exercise, index) => ({
          ...exercise,
          audioFile: exercise.audioFile,
          attachment: exercise.attachment,
          sortOrder: index
        }))
      };
      return from(this.backend.createExerciseSet(exerciseSet)).pipe(ignoreElements());
    });

    this.tasks.register(fetchStudentExerciseSets, action =>
      concat(
        of(clearStudentExerciseSets()),
        this.backend.listExerciseSets(action.studentId).pipe(
          map(result =>
            addStudentExerciseSets({
              exerciseSet: result.map(exerciseSet => ({ ...exerciseSet, exercises: exerciseSet.exercises || [] }))
            })
          )
        )
      )
    );

    this.tasks.register(addStudentNotes, action => from(this.backend.createNotes(action.notes)).pipe(ignoreElements()));
    this.tasks.register(fetchStudentNotes, action =>
      concat(of(clearStudentNotes()), this.backend.listNotes(action.studentId).pipe(map(notes => addManyStudentNotes({ notes }))))
    );
  }
}

import { Action, combineReducers } from '@ngrx/store';
import * as fromStudentExerciseSet from './student-exercise-set/student-exercise-set.reducer';
import * as fromStudentNotes from './student-notes/student-notes.reducer';

export const studentJournalFeatureKey = 'studentJournal';

export interface State {
  exerciseSet: fromStudentExerciseSet.State;
  notes: fromStudentNotes.State;
}

export const initialState: State = {
  exerciseSet: fromStudentExerciseSet.initialState,
  notes: fromStudentNotes.initialState
};

const studentJournalReducer = combineReducers({ exerciseSet: fromStudentExerciseSet.reducer, notes: fromStudentNotes.reducer });

export function reducer(state: State | undefined, action: Action) {
  return studentJournalReducer(state, action);
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Student } from 'src/app/model/student';
import * as StudentActions from './student.actions';

export const studentsFeatureKey = 'students';

export interface StudentState extends EntityState<Student> {
  // additional entities state properties
}

export function sortByName(a: Student, b: Student): number {
  if (Number.isInteger(a.sortOrder) && Number.isInteger(b.sortOrder)) {
    return a.sortOrder - b.sortOrder;
  } else if (Number.isInteger(a.sortOrder) && !Number.isInteger(b.sortOrder)) {
    return 1;
  } else if (!Number.isInteger(a.sortOrder) && Number.isInteger(b.sortOrder)) {
    return -1;
  } else {
    return a.name.localeCompare(b.name);
  }
}

export const adapter: EntityAdapter<Student> = createEntityAdapter<Student>({ sortComparer: sortByName });

export const initialState: StudentState = adapter.getInitialState({
  // additional entity state properties
});

const studentReducer = createReducer(
  initialState,
  on(StudentActions.addStudent, (state, action) => adapter.addOne(action.student, state)),
  // on(StudentActions.upsertStudent, (state, action) => adapter.upsertOne(action.student, state)),
  on(StudentActions.addStudents, (state, action) => adapter.addMany(action.students, state)),
  // on(StudentActions.upsertStudents, (state, action) => adapter.upsertMany(action.students, state)),
  on(StudentActions.updateStudent, (state, action) => adapter.updateOne({ id: action.student.id, changes: action.student }, state)),
  // on(StudentActions.updateStudents, (state, action) => adapter.updateMany(action.students, state)),
  on(StudentActions.deleteStudent, (state, action) => adapter.removeOne(action.id, state))
  // on(StudentActions.deleteStudents, (state, action) => adapter.removeMany(action.ids, state)),
  // on(StudentActions.clearStudents, state => adapter.removeAll(state))
);

export function reducer(state: StudentState | undefined, action: Action) {
  return studentReducer(state, action);
}

import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ExerciseArchiveActions } from './exercise-archive.actions';
import { Exercise } from '../../model/exercise';

export interface ExerciseArchiveState extends EntityState<Exercise> {
  pagination: { nextPageToken?: string };
  loading: boolean;
}

export const adapter: EntityAdapter<Exercise> = createEntityAdapter<Exercise>();

export const initialState: ExerciseArchiveState = adapter.getInitialState({
  pagination: {},
  loading: false
});

const exerciseArchiveReducer = createReducer(
  initialState,
  on(ExerciseArchiveActions.addLoadedExercises, (state, action) => ({
    ...adapter.addMany(action.exercises, state),
    loading: false,
    pagination: { nextPageToken: action.nextPageToken }
  })),
  on(ExerciseArchiveActions.fetchNextPage, state => ({
    ...state,
    loading: true
  })),
  on(ExerciseArchiveActions.clearExercises, state => ({ ...adapter.removeAll(state), pagination: {} }))
);

export function reducer(state: ExerciseArchiveState | undefined, action: Action) {
  return exerciseArchiveReducer(state, action);
}

import { Injectable } from '@angular/core';
import { EMPTY, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
  handleError(error: any): void {
    console.error(JSON.stringify(error, null, 4));
  }

  logErrors<T>(): MonoTypeOperatorFunction<T> {
    return tap({ error: error => this.handleError(error) });
  }

  catch(error: any): Observable<any> {
    this.handleError(error);
    return EMPTY;
  }
}

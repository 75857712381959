import { Injectable } from '@angular/core';
import { ExerciseSet } from '../model/exercise-set';
import { Student } from '../model/student';
import { from, merge, NEVER, Observable, ObservableInput, Subject } from 'rxjs';
import { concatMap, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Entity } from '../model/entity';
import { Exercise } from '../model/exercise';
import { Notes } from '../model/notes';
import { APIService, ModelSortDirection } from './api.service';
import { AudioRecordingLink } from 'src/app/model/exercise';
import { ApiCustomService } from './api-custom.service';

export interface QueryResult<T> {
  items?: Array<T>;
  nextToken?: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  onCreateAudioRecording$: Observable<AudioRecordingLink> = this.apiCustomService.onCreateAudioRecordingLink$.pipe(
    map(response => this.convertResultItem<AudioRecordingLink>(response))
  );

  onUpdateAudioRecording$: Observable<AudioRecordingLink> = this.apiCustomService.onUpdateAudioRecordingLink$.pipe(
    map(response => this.convertResultItem<AudioRecordingLink>(response))
  );

  onDeleteAudioRecording$: Observable<AudioRecordingLink> = this.apiCustomService.onDeleteAudioRecordingLink$.pipe(
    map(response => this.convertResultItem<AudioRecordingLink>(response))
  );

  constructor(private api: APIService, private apiCustomService: ApiCustomService, private authService: AuthService) {}

  async createStudent(student: Student): Promise<any> {
    return this.api.CreateStudent(this.stripDateFields(student));
  }

  async updateStudent(student: Student): Promise<any> {
    return this.api.UpdateStudent(this.stripDateFields(student));
  }

  async createExerciseSet(exerciseSet: ExerciseSet): Promise<any> {
    // TODO do this in on GraphQL mutation
    return from(this.api.CreateExerciseSet({ studentId: exerciseSet.studentId, id: exerciseSet.id }))
      .pipe(
        switchMap(persistedExerciseSet =>
          merge(...exerciseSet.exercises.map(exercise => this.createExercise(exercise, persistedExerciseSet.id)))
        )
      )
      .toPromise();
  }

  private async createExercise(exercise: Exercise, exerciseSetId: string): Promise<any> {
    return this.api.CreateExercise(
      this.stripDateFields({
        ...exercise,
        audioFile: exercise.audioFile,
        attachment: exercise.attachment,
        exerciseSetId
      } as Entity)
    );
  }

  async createNotes(notes: Notes): Promise<any> {
    return this.api.CreateNotes(this.stripDateFields(notes));
  }

  listNotes(studentId: string): Observable<Notes[]> {
    return this.fetchExhausting(async (nextToken, limit) => {
      const queryResult = await this.api.ListNotes({ studentId: { attributeExists: true, eq: studentId } }, limit, nextToken);
      return {
        nextToken: queryResult.nextToken,
        items: queryResult.items.map(item => this.convertResultItem<Notes>(item))
      };
    });
  }

  listStudents(): Observable<Student[]> {
    return this.fetchExhausting(async (nextToken, limit) => {
      const queryResult = await this.api.customListStudents(undefined, undefined, nextToken);
      return {
        nextToken: queryResult.nextToken,
        items: queryResult.items.map(item => this.convertResultItem<Student>(item))
      };
    });
  }

  listExerciseSets(studentId: string): Observable<ExerciseSet[]> {
    return this.fetchExhausting(async (nextToken, limit) => {
      const result = await this.api.ListCompleteExerciseSets({ studentId: { attributeExists: true, eq: studentId } }, limit, nextToken);
      return {
        nextToken: result.nextToken,
        items: result.items.map(set => {
          let exercises = set.exercises.items;
          if (!exercises.length) {
            exercises = set.exercisesUnsorted.items;
          }

          return {
            ...this.convertResultItem<ExerciseSet>(set),
            exercises: exercises.map(exercise => this.convertResultItem<Exercise>(exercise))
          };
        })
      };
    });
  }

  async listExercises(limit?: number, nextToken?: string): Promise<QueryResult<Exercise>> {
    return this.authService.currentUser$
      .pipe(
        take(1),
        switchMap(userData =>
          this.api.GetExerciseByUpdatedOn(userData.sub, undefined, ModelSortDirection.DESC, undefined, limit, nextToken)
        ),
        map(result => ({ nextToken: result.nextToken, items: result.items.map(exercise => this.convertResultItem<Exercise>(exercise)) }))
      )
      .toPromise();
  }

  createRecording(audioRecording: AudioRecordingLink): Promise<any> {
    return this.api.CreateAudioRecordingLink(this.stripDateFields(audioRecording));
  }

  updateRecording(id: string, audioRecording: Partial<AudioRecordingLink>): Promise<any> {
    return this.api.UpdateAudioRecordingLink(this.stripDateFields({ id, ...audioRecording }));
  }

  deleteRecording(id: string): Promise<any> {
    return this.api.DeleteAudioRecordingLink({ id });
  }

  listRecordings(): Observable<AudioRecordingLink[]> {
    return this.fetchExhausting<AudioRecordingLink>(async (nextToken, limit) => {
      const queryResult = await this.api.ListAudioRecordingLinks(undefined, limit, nextToken);
      return {
        nextToken: queryResult.nextToken,
        items: queryResult.items.map(item => this.convertResultItem<AudioRecordingLink>(item))
      };
    });
  }

  private convertResultItem<T extends Entity>(item: { createdOn: string; updatedOn: string }): T {
    return JSON.parse(JSON.stringify(item), (key: string, value) => {
      if (key === 'createdOn' || key === 'updatedOn') {
        return new Date(value);
      } else if (key === '__typename') {
        return undefined;
      } else {
        return value;
      }
    });
  }

  private fetchExhausting<T>(
    apiFn: (nextToken: string, limit: number) => ObservableInput<{ nextToken?: string; items?: T[] }>
  ): Observable<T[]> {
    const nextToken$ = new Subject<string | undefined>();

    return nextToken$.pipe(
      startWith(undefined),
      concatMap(nextToken => apiFn(nextToken, 1000)),
      tap(nextResult => {
        if (nextResult.nextToken) {
          nextToken$.next(nextResult.nextToken);
        } else {
          nextToken$.complete();
        }
      }),
      map(next => next.items)
    );
  }

  private stripDateFields<T>(item: T): any {
    return { ...item, createdOn: undefined, updatedOn: undefined, owner: undefined };
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, ExerciseArchiveState } from './exercise-archive.reducer';

export const exercisesArchiveFeatureKey = 'exercisesArchive';

export const selectExerciseArchiveFeatureSelector = createFeatureSelector<ExerciseArchiveState>(exercisesArchiveFeatureKey);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors(selectExerciseArchiveFeatureSelector);
const nextPageToken = createSelector(selectExerciseArchiveFeatureSelector, state => state.pagination.nextPageToken);
const hasMorePages = createSelector(nextPageToken, token => !!token);
const isLoading = createSelector(selectExerciseArchiveFeatureSelector, state => state.loading);

export const ExerciseArchiveSelectors = {
  selectAll,
  hasMorePages,
  nextPageToken,
  isLoading
};

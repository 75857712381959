/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:52a96a3d-613e-4fd6-bb5c-97592b3ec4b2",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_jEjrmiQz4",
    "aws_user_pools_web_client_id": "2ne113t129dh1dnm2gqh84oadv",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "exercise-exchange-bucket120255-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://csfi3yrerfgjdgeqc6mywqnx7q.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "userjourney",
            "endpoint": "https://5j49bz77x8.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "auth",
            "endpoint": "https://6ni41bsufg.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { concatMap } from 'rxjs/operators';
import { BackendTasksService } from './backend-tasks.service';
import { StudentBackendTasksService } from './student-backend.tasks.service';
import { StudentJournalBackendTasksService } from './student-journal-backend.tasks.service';
import { ExerciseArchiveBackendTasksService } from './exercise-archive-backend.tasks.service';
import { RecordingBackendTasksService } from './recording-backend.tasks.service';

@Injectable()
export class BackendEffect {
  captureAllActions$ = createEffect(() => {
    return this.actions$.pipe(concatMap(action => this.backendTasksService.execute(action)));
  });

  constructor(
    private actions$: Actions,
    private backendTasksService: BackendTasksService,
    studentBackendTasksService: StudentBackendTasksService,
    studentJournalBackendTasksService: StudentJournalBackendTasksService,
    exerciseArchiveBackendTasksService: ExerciseArchiveBackendTasksService,
    recordingBackendTasksService: RecordingBackendTasksService
  ) {
    studentBackendTasksService.init();
    studentJournalBackendTasksService.init();
    exerciseArchiveBackendTasksService.init();
    recordingBackendTasksService.init();
  }
}

import { createAction, props } from '@ngrx/store';
import { AudioRecordingLink } from 'src/app/model/exercise';

const addRecording = createAction('[Recording/API] Add Recording', props<{ audioRecording: AudioRecordingLink }>());
const addRecordings = createAction('[Recording/API] Add Recordings', props<{ audioRecordings: AudioRecordingLink[] }>());

const addRecordingFromBackend = createAction('[Recording/API] Add Recording from Backend', props<{ audioRecording: AudioRecordingLink }>());

const updateRecording = createAction('[Recording/API] Update Recording', props<{ audioRecording: Partial<AudioRecordingLink> }>());

const deleteRecording = createAction('[Recording/API] Delete Recording', props<{ id: string }>());

const fetchRecordings = createAction('[Recording/API] Fetch Recording');

const UpdateRecordingFromBackend = createAction(
  '[Recording/API] Update Recording from Backend',
  props<{ audioRecording: AudioRecordingLink }>()
);

const DeleteRecordingFromBackend = createAction(
  '[Recording/API] Delete Recording from Backend',
  props<{ id: string }>()
);

export const RecordingsActions = {
  addRecording,
  addRecordings,
  updateRecording,
  deleteRecording,
  addRecordingFromBackend,
  UpdateRecordingFromBackend,
  DeleteRecordingFromBackend,
  fetchRecordings
};

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AnalyticsService } from './analytics/analytics.service';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'trivs-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'trivs-web';

  authInitialized$: Observable<boolean>;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private titleService: Title
  ) {
    this.titleService.setTitle('trıvs coach');
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.analyticsService.init();
      this.splashScreen.hide();
    });
  }

  ngOnInit(): void {
    this.authService.init();
    this.authInitialized$ = this.authService.authenticated$.pipe(map(() => true));

    this.authService.currentUser$
      .pipe(
        filter(user => !!user),
        take(1)
      )
      .subscribe(user => {
        (window as any).appziSettings = {
          data: {
            email: user.email,
            userId: user.username
          }
        };
      });
  }
}

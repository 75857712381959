import { Injectable } from '@angular/core';
import { addStudent, addStudents, fetchStudents, updateStudent } from '../store/student/student.actions';
import { BackendTasksService } from './backend-tasks.service';
import { from } from 'rxjs';
import { ignoreElements, map } from 'rxjs/operators';
import { BackendService } from './backend.service';

@Injectable({ providedIn: 'root' })
export class StudentBackendTasksService {
  constructor(private backend: BackendService, private tasks: BackendTasksService) {}

  init(): void {
    this.tasks.register(addStudent, action => from(this.backend.createStudent(action.student)).pipe(ignoreElements()));
    this.tasks.register(updateStudent, action => from(this.backend.updateStudent(action.student)).pipe(ignoreElements()));
    this.tasks.register(fetchStudents, () => this.backend.listStudents().pipe(map(students => addStudents({ students }))));
  }
}

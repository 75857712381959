import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as StudentExerciseSetActions from './student-exercise-set.actions';
import { ExerciseSet } from '../../../model/exercise-set';

export interface State extends EntityState<ExerciseSet> {
  // additional entities state properties
}

export const adapter: EntityAdapter<ExerciseSet> = createEntityAdapter<ExerciseSet>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

const studentExerciseSetReducer = createReducer(
  initialState,
  on(StudentExerciseSetActions.addStudentExerciseSet, (state, action) => adapter.addOne(action.exerciseSet, state)),
  on(StudentExerciseSetActions.addStudentExerciseSets, (state, action) => adapter.addMany(action.exerciseSet, state)),
  on(StudentExerciseSetActions.clearStudentExerciseSets, state => adapter.removeAll(state))
);

export function reducer(state: State | undefined, action: Action) {
  return studentExerciseSetReducer(state, action);
}

import {Injectable} from '@angular/core';
import {map, take, tap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {addStudent, addStudents} from '../store/student/student.actions';
import {UserguidingService} from './userguiding.service';
import {ExerciseArchiveActions} from '../store/exercises-archive/exercise-archive.actions';
import {merge} from 'rxjs';
import {addStudentExerciseSet} from '../store/student-journal/student-exercise-set/student-exercise-set.actions';

@Injectable()
export class UserguidingEffectsService {
  studentsLoaded$ = createEffect(
    () =>
      merge(
        this.actions$.pipe(
          ofType(addStudent),
          map(() => 1),
          take(1)
        ),
        this.actions$.pipe(
          ofType(addStudents),
          map(action => action.students.length),
          take(1)
        )
      ).pipe(
        tap(students => {
          this.userguidingService.setStudentsCount(students);
        })
      ),
    {dispatch: false}
  );

  exercisesLoaded$ = createEffect(
    () => {
      return merge(
        this.actions$.pipe(
          ofType(addStudentExerciseSet),
          map(() => 1),
          take(1)
        ),
        this.actions$.pipe(
          ofType(ExerciseArchiveActions.addLoadedExercises),
          map(action => action.exercises.length),
          take(1)
        )
      ).pipe(tap(exercises => this.userguidingService.setExercisesCount(exercises)));
    },
    {dispatch: false}
  );

  constructor(private actions$: Actions, private userguidingService: UserguidingService) {
  }
}

import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { BackendTasksService } from './backend-tasks.service';
import { from } from 'rxjs';
import { ignoreElements, map, tap } from 'rxjs/operators';
import { RecordingsActions } from '../store/recordings/recordings.actions';
import { State } from '../store';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class RecordingBackendTasksService {
  constructor(private backend: BackendService, private tasks: BackendTasksService, private store$: Store<State>) {}

  init(): void {
    this.tasks.register(RecordingsActions.addRecording, action =>
      from(this.backend.createRecording(action.audioRecording)).pipe(ignoreElements())
    );
    this.tasks.register(RecordingsActions.updateRecording, action =>
      from(this.backend.updateRecording(action.audioRecording.id, action.audioRecording)).pipe(ignoreElements())
    );
    this.tasks.register(RecordingsActions.deleteRecording, action => from(this.backend.deleteRecording(action.id)).pipe(ignoreElements()));
    this.tasks.register(RecordingsActions.fetchRecordings, () => {
      return this.backend.listRecordings().pipe(map(audioRecordings => RecordingsActions.addRecordings({ audioRecordings })));
    });

    this.backend.onCreateAudioRecording$.subscribe(audioRecording =>
      this.store$.dispatch(RecordingsActions.addRecordingFromBackend({ audioRecording }))
    );

    this.backend.onUpdateAudioRecording$.subscribe(audioRecording =>
      this.store$.dispatch(RecordingsActions.UpdateRecordingFromBackend({ audioRecording }))
    );
    this.backend.onDeleteAudioRecording$.subscribe(audioRecording => {
      this.store$.dispatch(RecordingsActions.DeleteRecordingFromBackend({ id: audioRecording.id }));
    });
  }
}

import { MetaReducer } from '@ngrx/store';
import { ExerciseArchiveState } from './exercise-archive.reducer';
import { ExerciseArchiveActions } from './exercise-archive.actions';
import { ExerciseArchiveSelectors } from './exercise-archive.selector';

// export interface ExerciseArchiveState {}

export { reducer } from './exercise-archive.reducer';

export const metaReducers: MetaReducer<ExerciseArchiveState>[] = [];

export const ExerciseArchive = {
  ...ExerciseArchiveActions,
  ...ExerciseArchiveSelectors
};

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import * as Sentry from '@sentry/angular';
import { RootStoreModule } from './store/root-store.module';
import { CustomRouteSerializer } from './route-serializer';
import { environment } from '../environments/environment';
import { UserguidingService } from './userguiding/userguiding.service';
import { HttpClientModule } from '@angular/common/http';
/* Configure Amplify resources */
Amplify.configure(awsconfig);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ backButtonText: '', mode: 'ios', animated: false }),
    AppRoutingModule,
    FormsModule,
    RootStoreModule,
    StoreRouterConnectingModule.forRoot({ serializer: CustomRouteSerializer }),
    HttpClientModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: ErrorHandler,
      useValue: environment.sentry
        ? Sentry.createErrorHandler({
            showDialog: false
          })
        : new ErrorHandler()
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(userguidingService: UserguidingService) {
    userguidingService.init();
  }
}

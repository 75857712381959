import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { AudioRecordingLink } from 'src/app/model/exercise';
import { RecordingsActions } from './recordings.actions';

export const recordingsFeatureKey = 'recordings';

export interface RecordingsState extends EntityState<AudioRecordingLink> {
  // additional entities state properties
}

export const adapter: EntityAdapter<AudioRecordingLink> = createEntityAdapter<AudioRecordingLink>();

export const initialState: RecordingsState = adapter.getInitialState({
  // additional entity state properties
});

const recodingReducer = createReducer(
  initialState,
  on(RecordingsActions.addRecording, (state, action) => adapter.addOne(action.audioRecording, state)),
  on(RecordingsActions.addRecordings, (state, action) => adapter.addMany(action.audioRecordings, state)),
  on(RecordingsActions.updateRecording, (state, action) => {
    return adapter.updateOne({ id: action.audioRecording.id, changes: action.audioRecording }, state);
  }),
  on(RecordingsActions.deleteRecording, (state, action) => adapter.removeOne(action.id, state)),
  on(RecordingsActions.addRecordingFromBackend, (state, action) => {
    const { audioRecording } = action;
    if (state.ids[audioRecording.id]) {
      return state;
    } else {
      return adapter.addOne(audioRecording, state);
    }
  }),
  on(RecordingsActions.UpdateRecordingFromBackend, (state, action) =>
    adapter.updateOne({ id: action.audioRecording.id, changes: action.audioRecording }, state)
  ),
  on(RecordingsActions.DeleteRecordingFromBackend, (state, action) => adapter.removeOne(action.id, state))
);

export function reducer(state: RecordingsState | undefined, action: Action) {
  return recodingReducer(state, action);
}

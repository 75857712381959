import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.auth.authenticated$.pipe(
      take(1),
      switchMap(authenticated => {
        if (!authenticated) {
          return this.auth.authenticateWithTrivsToken(route);
        } else {
          return of(true);
        }
      }),
      map(authenticated => {
        if (!authenticated) {
          return this.router.createUrlTree(['login'], { queryParams: { returnUrl: state.url } });
        } else {
          return true;
        }
      })
    );
  }
}

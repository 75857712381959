/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from '@angular/core';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { Observable } from 'zen-observable-ts';

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateStudentInput = {
  id?: string | null;
  name: string;
  email: string;
  additionalEmail?: string | null;
  createdOn?: string | null;
  updatedOn?: string | null;
};

export type ModelStudentConditionInput = {
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  additionalEmail?: ModelStringInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  and?: Array<ModelStudentConditionInput | null> | null;
  or?: Array<ModelStudentConditionInput | null> | null;
  not?: ModelStudentConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = 'binary',
  binarySet = 'binarySet',
  bool = 'bool',
  list = 'list',
  map = 'map',
  number = 'number',
  numberSet = 'numberSet',
  string = 'string',
  stringSet = 'stringSet',
  _null = '_null'
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type Student = {
  __typename: 'Student';
  id?: string;
  name?: string;
  email?: string;
  additionalEmail?: string | null;
  exerciseSets?: ModelExerciseSetConnection;
  notes?: ModelNotesConnection;
  createdOn?: string;
  updatedOn?: string;
  owner?: string | null;
};

export type ModelExerciseSetConnection = {
  __typename: 'ModelExerciseSetConnection';
  items?: Array<ExerciseSet>;
  nextToken?: string | null;
};

export type ExerciseSet = {
  __typename: 'ExerciseSet';
  id?: string;
  studentId?: string;
  exercisesUnsorted?: ModelExerciseConnection;
  exercises?: ModelExerciseConnection;
  createdOn?: string;
  updatedOn?: string;
  owner?: string | null;
};

export type ModelExerciseConnection = {
  __typename: 'ModelExerciseConnection';
  items?: Array<Exercise>;
  nextToken?: string | null;
};

export type Exercise = {
  __typename: 'Exercise';
  id?: string;
  owner?: string | null;
  title?: string;
  exerciseSetId?: string;
  description?: string | null;
  metronomeConfig?: MetronomeConfig;
  audioFile?: FileLink;
  attachment?: FileLink;
  practiceDuration?: number;
  sortOrder?: number | null;
  createdOn?: string;
  updatedOn?: string;
};

export type MetronomeConfig = {
  __typename: 'MetronomeConfig';
  bpm?: number;
  timeSignature?: TimeSignature;
};

export type TimeSignature = {
  __typename: 'TimeSignature';
  unit?: number;
  count?: number;
};

export type FileLink = {
  __typename: 'FileLink';
  id?: string;
  name?: string;
  size?: number;
  url?: string;
  contentType?: string | null;
};

export type ModelNotesConnection = {
  __typename: 'ModelNotesConnection';
  items?: Array<Notes>;
  nextToken?: string | null;
};

export type Notes = {
  __typename: 'Notes';
  id?: string;
  studentId?: string;
  content?: string;
  createdOn?: string;
  updatedOn?: string;
  owner?: string | null;
};

export type UpdateStudentInput = {
  id: string;
  name?: string | null;
  email?: string | null;
  additionalEmail?: string | null;
  createdOn?: string | null;
  updatedOn?: string | null;
};

export type DeleteStudentInput = {
  id: string;
};

export type CreateExerciseSetInput = {
  id?: string | null;
  studentId: string;
  createdOn?: string | null;
  updatedOn?: string | null;
};

export type ModelExerciseSetConditionInput = {
  studentId?: ModelIDInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  and?: Array<ModelExerciseSetConditionInput | null> | null;
  or?: Array<ModelExerciseSetConditionInput | null> | null;
  not?: ModelExerciseSetConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateExerciseSetInput = {
  id: string;
  studentId?: string | null;
  createdOn?: string | null;
  updatedOn?: string | null;
};

export type DeleteExerciseSetInput = {
  id: string;
};

export type CreateExerciseInput = {
  id?: string | null;
  owner?: string | null;
  title: string;
  exerciseSetId: string;
  description?: string | null;
  metronomeConfig: MetronomeConfigInput;
  audioFile?: FileLinkInput | null;
  attachment?: FileLinkInput | null;
  practiceDuration: number;
  sortOrder?: number | null;
  createdOn?: string | null;
  updatedOn?: string | null;
};

export type MetronomeConfigInput = {
  bpm: number;
  timeSignature: TimeSignatureInput;
};

export type TimeSignatureInput = {
  unit: number;
  count: number;
};

export type FileLinkInput = {
  id?: string | null;
  name: string;
  size: number;
  url: string;
  contentType?: string | null;
};

export type ModelExerciseConditionInput = {
  owner?: ModelStringInput | null;
  title?: ModelStringInput | null;
  exerciseSetId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  practiceDuration?: ModelIntInput | null;
  sortOrder?: ModelIntInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  and?: Array<ModelExerciseConditionInput | null> | null;
  or?: Array<ModelExerciseConditionInput | null> | null;
  not?: ModelExerciseConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateExerciseInput = {
  id: string;
  owner?: string | null;
  title?: string | null;
  exerciseSetId?: string | null;
  description?: string | null;
  metronomeConfig?: MetronomeConfigInput | null;
  audioFile?: FileLinkInput | null;
  attachment?: FileLinkInput | null;
  practiceDuration?: number | null;
  sortOrder?: number | null;
  createdOn?: string | null;
  updatedOn?: string | null;
};

export type DeleteExerciseInput = {
  id: string;
};

export type CreateNotesInput = {
  id?: string | null;
  studentId: string;
  content: string;
  createdOn?: string | null;
  updatedOn?: string | null;
};

export type ModelNotesConditionInput = {
  studentId?: ModelIDInput | null;
  content?: ModelStringInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  and?: Array<ModelNotesConditionInput | null> | null;
  or?: Array<ModelNotesConditionInput | null> | null;
  not?: ModelNotesConditionInput | null;
};

export type UpdateNotesInput = {
  id: string;
  studentId?: string | null;
  content?: string | null;
  createdOn?: string | null;
  updatedOn?: string | null;
};

export type DeleteNotesInput = {
  id: string;
};

export type CreateAudioRecordingLinkInput = {
  id?: string | null;
  createdOn?: string | null;
  updatedOn?: string | null;
  name: string;
  size: number;
  duration: number;
  uploadDone: boolean;
  url?: string | null;
  contentType?: string | null;
};

export type ModelAudioRecordingLinkConditionInput = {
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  name?: ModelStringInput | null;
  size?: ModelIntInput | null;
  duration?: ModelIntInput | null;
  uploadDone?: ModelBooleanInput | null;
  url?: ModelStringInput | null;
  contentType?: ModelStringInput | null;
  and?: Array<ModelAudioRecordingLinkConditionInput | null> | null;
  or?: Array<ModelAudioRecordingLinkConditionInput | null> | null;
  not?: ModelAudioRecordingLinkConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type AudioRecordingLink = {
  __typename: 'AudioRecordingLink';
  id?: string | null;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  size?: number;
  duration?: number;
  uploadDone?: boolean;
  url?: string | null;
  contentType?: string | null;
  owner?: string | null;
};

export type UpdateAudioRecordingLinkInput = {
  id: string;
  createdOn?: string | null;
  updatedOn?: string | null;
  name?: string | null;
  size?: number | null;
  duration?: number | null;
  uploadDone?: boolean | null;
  url?: string | null;
  contentType?: string | null;
};

export type DeleteAudioRecordingLinkInput = {
  id: string;
};

export type ModelStudentFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  additionalEmail?: ModelStringInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  and?: Array<ModelStudentFilterInput | null> | null;
  or?: Array<ModelStudentFilterInput | null> | null;
  not?: ModelStudentFilterInput | null;
};

export type ModelStudentConnection = {
  __typename: 'ModelStudentConnection';
  items?: Array<Student>;
  nextToken?: string | null;
};

export type ModelExerciseSetFilterInput = {
  id?: ModelIDInput | null;
  studentId?: ModelIDInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  and?: Array<ModelExerciseSetFilterInput | null> | null;
  or?: Array<ModelExerciseSetFilterInput | null> | null;
  not?: ModelExerciseSetFilterInput | null;
};

export type ModelExerciseFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelStringInput | null;
  title?: ModelStringInput | null;
  exerciseSetId?: ModelIDInput | null;
  description?: ModelStringInput | null;
  practiceDuration?: ModelIntInput | null;
  sortOrder?: ModelIntInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  and?: Array<ModelExerciseFilterInput | null> | null;
  or?: Array<ModelExerciseFilterInput | null> | null;
  not?: ModelExerciseFilterInput | null;
};

export type ModelNotesFilterInput = {
  id?: ModelIDInput | null;
  studentId?: ModelIDInput | null;
  content?: ModelStringInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  and?: Array<ModelNotesFilterInput | null> | null;
  or?: Array<ModelNotesFilterInput | null> | null;
  not?: ModelNotesFilterInput | null;
};

export type ModelAudioRecordingLinkFilterInput = {
  id?: ModelIDInput | null;
  createdOn?: ModelStringInput | null;
  updatedOn?: ModelStringInput | null;
  name?: ModelStringInput | null;
  size?: ModelIntInput | null;
  duration?: ModelIntInput | null;
  uploadDone?: ModelBooleanInput | null;
  url?: ModelStringInput | null;
  contentType?: ModelStringInput | null;
  and?: Array<ModelAudioRecordingLinkFilterInput | null> | null;
  or?: Array<ModelAudioRecordingLinkFilterInput | null> | null;
  not?: ModelAudioRecordingLinkFilterInput | null;
};

export type ModelAudioRecordingLinkConnection = {
  __typename: 'ModelAudioRecordingLinkConnection';
  items?: Array<AudioRecordingLink>;
  nextToken?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type CreateStudentMutation = {
  __typename: 'Student';
  id: string;
  name: string;
  email: string;
  additionalEmail?: string | null;
  exerciseSets?: {
    __typename: 'ModelExerciseSetConnection';
    items: Array<{
      __typename: 'ExerciseSet';
      id: string;
      studentId: string;
      exercisesUnsorted?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      exercises?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: 'ModelNotesConnection';
    items: Array<{
      __typename: 'Notes';
      id: string;
      studentId: string;
      content: string;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type UpdateStudentMutation = {
  __typename: 'Student';
  id: string;
  name: string;
  email: string;
  additionalEmail?: string | null;
  exerciseSets?: {
    __typename: 'ModelExerciseSetConnection';
    items: Array<{
      __typename: 'ExerciseSet';
      id: string;
      studentId: string;
      exercisesUnsorted?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      exercises?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: 'ModelNotesConnection';
    items: Array<{
      __typename: 'Notes';
      id: string;
      studentId: string;
      content: string;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type DeleteStudentMutation = {
  __typename: 'Student';
  id: string;
  name: string;
  email: string;
  additionalEmail?: string | null;
  exerciseSets?: {
    __typename: 'ModelExerciseSetConnection';
    items: Array<{
      __typename: 'ExerciseSet';
      id: string;
      studentId: string;
      exercisesUnsorted?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      exercises?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: 'ModelNotesConnection';
    items: Array<{
      __typename: 'Notes';
      id: string;
      studentId: string;
      content: string;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type CreateExerciseSetMutation = {
  __typename: 'ExerciseSet';
  id: string;
  studentId: string;
  exercisesUnsorted?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  exercises?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type UpdateExerciseSetMutation = {
  __typename: 'ExerciseSet';
  id: string;
  studentId: string;
  exercisesUnsorted?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  exercises?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type DeleteExerciseSetMutation = {
  __typename: 'ExerciseSet';
  id: string;
  studentId: string;
  exercisesUnsorted?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  exercises?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type CreateExerciseMutation = {
  __typename: 'Exercise';
  id: string;
  owner?: string | null;
  title: string;
  exerciseSetId: string;
  description?: string | null;
  metronomeConfig: {
    __typename: 'MetronomeConfig';
    bpm: number;
    timeSignature: {
      __typename: 'TimeSignature';
      unit: number;
      count: number;
    };
  };
  audioFile?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  attachment?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  practiceDuration: number;
  sortOrder?: number | null;
  createdOn: string;
  updatedOn: string;
};

export type UpdateExerciseMutation = {
  __typename: 'Exercise';
  id: string;
  owner?: string | null;
  title: string;
  exerciseSetId: string;
  description?: string | null;
  metronomeConfig: {
    __typename: 'MetronomeConfig';
    bpm: number;
    timeSignature: {
      __typename: 'TimeSignature';
      unit: number;
      count: number;
    };
  };
  audioFile?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  attachment?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  practiceDuration: number;
  sortOrder?: number | null;
  createdOn: string;
  updatedOn: string;
};

export type DeleteExerciseMutation = {
  __typename: 'Exercise';
  id: string;
  owner?: string | null;
  title: string;
  exerciseSetId: string;
  description?: string | null;
  metronomeConfig: {
    __typename: 'MetronomeConfig';
    bpm: number;
    timeSignature: {
      __typename: 'TimeSignature';
      unit: number;
      count: number;
    };
  };
  audioFile?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  attachment?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  practiceDuration: number;
  sortOrder?: number | null;
  createdOn: string;
  updatedOn: string;
};

export type CreateNotesMutation = {
  __typename: 'Notes';
  id: string;
  studentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type UpdateNotesMutation = {
  __typename: 'Notes';
  id: string;
  studentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type DeleteNotesMutation = {
  __typename: 'Notes';
  id: string;
  studentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type CreateAudioRecordingLinkMutation = {
  __typename: 'AudioRecordingLink';
  id?: string | null;
  createdOn: string;
  updatedOn: string;
  name: string;
  size: number;
  duration: number;
  uploadDone: boolean;
  url?: string | null;
  contentType?: string | null;
  owner?: string | null;
};

export type UpdateAudioRecordingLinkMutation = {
  __typename: 'AudioRecordingLink';
  id?: string | null;
  createdOn: string;
  updatedOn: string;
  name: string;
  size: number;
  duration: number;
  uploadDone: boolean;
  url?: string | null;
  contentType?: string | null;
  owner?: string | null;
};

export type DeleteAudioRecordingLinkMutation = {
  __typename: 'AudioRecordingLink';
  id?: string | null;
  createdOn: string;
  updatedOn: string;
  name: string;
  size: number;
  duration: number;
  uploadDone: boolean;
  url?: string | null;
  contentType?: string | null;
  owner?: string | null;
};

export type GetStudentQuery = {
  __typename: 'Student';
  id: string;
  name: string;
  email: string;
  additionalEmail?: string | null;
  exerciseSets?: {
    __typename: 'ModelExerciseSetConnection';
    items: Array<{
      __typename: 'ExerciseSet';
      id: string;
      studentId: string;
      exercisesUnsorted?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      exercises?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: 'ModelNotesConnection';
    items: Array<{
      __typename: 'Notes';
      id: string;
      studentId: string;
      content: string;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type ListStudentsQuery = {
  __typename: 'ModelStudentConnection';
  items: Array<{
    __typename: 'Student';
    id: string;
    name: string;
    email: string;
    additionalEmail?: string | null;
    exerciseSets?: {
      __typename: 'ModelExerciseSetConnection';
      items: Array<{
        __typename: 'ExerciseSet';
        id: string;
        studentId: string;
        exercisesUnsorted?: {
          __typename: 'ModelExerciseConnection';
          items: Array<{
            __typename: 'Exercise';
            id: string;
            owner?: string | null;
            title: string;
            exerciseSetId: string;
            description?: string | null;
            practiceDuration: number;
            sortOrder?: number | null;
            createdOn: string;
            updatedOn: string;
          }>;
          nextToken?: string | null;
        } | null;
        exercises?: {
          __typename: 'ModelExerciseConnection';
          items: Array<{
            __typename: 'Exercise';
            id: string;
            owner?: string | null;
            title: string;
            exerciseSetId: string;
            description?: string | null;
            practiceDuration: number;
            sortOrder?: number | null;
            createdOn: string;
            updatedOn: string;
          }>;
          nextToken?: string | null;
        } | null;
        createdOn: string;
        updatedOn: string;
        owner?: string | null;
      }>;
      nextToken?: string | null;
    } | null;
    notes?: {
      __typename: 'ModelNotesConnection';
      items: Array<{
        __typename: 'Notes';
        id: string;
        studentId: string;
        content: string;
        createdOn: string;
        updatedOn: string;
        owner?: string | null;
      }>;
      nextToken?: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner?: string | null;
  }>;
  nextToken?: string | null;
};

export type GetExerciseSetQuery = {
  __typename: 'ExerciseSet';
  id: string;
  studentId: string;
  exercisesUnsorted?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  exercises?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type ListExerciseSetsQuery = {
  __typename: 'ModelExerciseSetConnection';
  items: Array<{
    __typename: 'ExerciseSet';
    id: string;
    studentId: string;
    exercisesUnsorted?: {
      __typename: 'ModelExerciseConnection';
      items: Array<{
        __typename: 'Exercise';
        id: string;
        owner?: string | null;
        title: string;
        exerciseSetId: string;
        description?: string | null;
        metronomeConfig: {
          __typename: 'MetronomeConfig';
          bpm: number;
          timeSignature: {
            __typename: 'TimeSignature';
            unit: number;
            count: number;
          };
        };
        audioFile?: {
          __typename: 'FileLink';
          id: string;
          name: string;
          size: number;
          url: string;
          contentType?: string | null;
        } | null;
        attachment?: {
          __typename: 'FileLink';
          id: string;
          name: string;
          size: number;
          url: string;
          contentType?: string | null;
        } | null;
        practiceDuration: number;
        sortOrder?: number | null;
        createdOn: string;
        updatedOn: string;
      }>;
      nextToken?: string | null;
    } | null;
    exercises?: {
      __typename: 'ModelExerciseConnection';
      items: Array<{
        __typename: 'Exercise';
        id: string;
        owner?: string | null;
        title: string;
        exerciseSetId: string;
        description?: string | null;
        metronomeConfig: {
          __typename: 'MetronomeConfig';
          bpm: number;
          timeSignature: {
            __typename: 'TimeSignature';
            unit: number;
            count: number;
          };
        };
        audioFile?: {
          __typename: 'FileLink';
          id: string;
          name: string;
          size: number;
          url: string;
          contentType?: string | null;
        } | null;
        attachment?: {
          __typename: 'FileLink';
          id: string;
          name: string;
          size: number;
          url: string;
          contentType?: string | null;
        } | null;
        practiceDuration: number;
        sortOrder?: number | null;
        createdOn: string;
        updatedOn: string;
      }>;
      nextToken?: string | null;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner?: string | null;
  }>;
  nextToken?: string | null;
};

export type GetExerciseQuery = {
  __typename: 'Exercise';
  id: string;
  owner?: string | null;
  title: string;
  exerciseSetId: string;
  description?: string | null;
  metronomeConfig: {
    __typename: 'MetronomeConfig';
    bpm: number;
    timeSignature: {
      __typename: 'TimeSignature';
      unit: number;
      count: number;
    };
  };
  audioFile?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  attachment?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  practiceDuration: number;
  sortOrder?: number | null;
  createdOn: string;
  updatedOn: string;
};

export type ListExercisesQuery = {
  __typename: 'ModelExerciseConnection';
  items: Array<{
    __typename: 'Exercise';
    id: string;
    owner?: string | null;
    title: string;
    exerciseSetId: string;
    description?: string | null;
    metronomeConfig: {
      __typename: 'MetronomeConfig';
      bpm: number;
      timeSignature: {
        __typename: 'TimeSignature';
        unit: number;
        count: number;
      };
    };
    audioFile?: {
      __typename: 'FileLink';
      id: string;
      name: string;
      size: number;
      url: string;
      contentType?: string | null;
    } | null;
    attachment?: {
      __typename: 'FileLink';
      id: string;
      name: string;
      size: number;
      url: string;
      contentType?: string | null;
    } | null;
    practiceDuration: number;
    sortOrder?: number | null;
    createdOn: string;
    updatedOn: string;
  }>;
  nextToken?: string | null;
};

export type GetNotesQuery = {
  __typename: 'Notes';
  id: string;
  studentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type ListNotesQuery = {
  __typename: 'ModelNotesConnection';
  items: Array<{
    __typename: 'Notes';
    id: string;
    studentId: string;
    content: string;
    createdOn: string;
    updatedOn: string;
    owner?: string | null;
  }>;
  nextToken?: string | null;
};

export type GetAudioRecordingLinkQuery = {
  __typename: 'AudioRecordingLink';
  id?: string | null;
  createdOn: string;
  updatedOn: string;
  name: string;
  size: number;
  duration: number;
  uploadDone: boolean;
  url?: string | null;
  contentType?: string | null;
  owner?: string | null;
};

export type ListAudioRecordingLinksQuery = {
  __typename: 'ModelAudioRecordingLinkConnection';
  items: Array<{
    __typename: 'AudioRecordingLink';
    id?: string | null;
    createdOn: string;
    updatedOn: string;
    name: string;
    size: number;
    duration: number;
    uploadDone: boolean;
    url?: string | null;
    contentType?: string | null;
    owner?: string | null;
  }>;
  nextToken?: string | null;
};

export type GetExerciseByUpdatedOnQuery = {
  __typename: 'ModelExerciseConnection';
  items: Array<{
    __typename: 'Exercise';
    id: string;
    owner?: string | null;
    title: string;
    exerciseSetId: string;
    description?: string | null;
    metronomeConfig: {
      __typename: 'MetronomeConfig';
      bpm: number;
      timeSignature: {
        __typename: 'TimeSignature';
        unit: number;
        count: number;
      };
    };
    audioFile?: {
      __typename: 'FileLink';
      id: string;
      name: string;
      size: number;
      url: string;
      contentType?: string | null;
    } | null;
    attachment?: {
      __typename: 'FileLink';
      id: string;
      name: string;
      size: number;
      url: string;
      contentType?: string | null;
    } | null;
    practiceDuration: number;
    sortOrder?: number | null;
    createdOn: string;
    updatedOn: string;
  }>;
  nextToken?: string | null;
};

export type OnCreateStudentSubscription = {
  __typename: 'Student';
  id: string;
  name: string;
  email: string;
  additionalEmail?: string | null;
  exerciseSets?: {
    __typename: 'ModelExerciseSetConnection';
    items: Array<{
      __typename: 'ExerciseSet';
      id: string;
      studentId: string;
      exercisesUnsorted?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      exercises?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: 'ModelNotesConnection';
    items: Array<{
      __typename: 'Notes';
      id: string;
      studentId: string;
      content: string;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnUpdateStudentSubscription = {
  __typename: 'Student';
  id: string;
  name: string;
  email: string;
  additionalEmail?: string | null;
  exerciseSets?: {
    __typename: 'ModelExerciseSetConnection';
    items: Array<{
      __typename: 'ExerciseSet';
      id: string;
      studentId: string;
      exercisesUnsorted?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      exercises?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: 'ModelNotesConnection';
    items: Array<{
      __typename: 'Notes';
      id: string;
      studentId: string;
      content: string;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnDeleteStudentSubscription = {
  __typename: 'Student';
  id: string;
  name: string;
  email: string;
  additionalEmail?: string | null;
  exerciseSets?: {
    __typename: 'ModelExerciseSetConnection';
    items: Array<{
      __typename: 'ExerciseSet';
      id: string;
      studentId: string;
      exercisesUnsorted?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      exercises?: {
        __typename: 'ModelExerciseConnection';
        items: Array<{
          __typename: 'Exercise';
          id: string;
          owner?: string | null;
          title: string;
          exerciseSetId: string;
          description?: string | null;
          metronomeConfig: {
            __typename: 'MetronomeConfig';
            bpm: number;
          };
          audioFile?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          attachment?: {
            __typename: 'FileLink';
            id: string;
            name: string;
            size: number;
            url: string;
            contentType?: string | null;
          } | null;
          practiceDuration: number;
          sortOrder?: number | null;
          createdOn: string;
          updatedOn: string;
        }>;
        nextToken?: string | null;
      } | null;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  notes?: {
    __typename: 'ModelNotesConnection';
    items: Array<{
      __typename: 'Notes';
      id: string;
      studentId: string;
      content: string;
      createdOn: string;
      updatedOn: string;
      owner?: string | null;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnCreateExerciseSetSubscription = {
  __typename: 'ExerciseSet';
  id: string;
  studentId: string;
  exercisesUnsorted?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  exercises?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnUpdateExerciseSetSubscription = {
  __typename: 'ExerciseSet';
  id: string;
  studentId: string;
  exercisesUnsorted?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  exercises?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnDeleteExerciseSetSubscription = {
  __typename: 'ExerciseSet';
  id: string;
  studentId: string;
  exercisesUnsorted?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  exercises?: {
    __typename: 'ModelExerciseConnection';
    items: Array<{
      __typename: 'Exercise';
      id: string;
      owner?: string | null;
      title: string;
      exerciseSetId: string;
      description?: string | null;
      metronomeConfig: {
        __typename: 'MetronomeConfig';
        bpm: number;
        timeSignature: {
          __typename: 'TimeSignature';
          unit: number;
          count: number;
        };
      };
      audioFile?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      attachment?: {
        __typename: 'FileLink';
        id: string;
        name: string;
        size: number;
        url: string;
        contentType?: string | null;
      } | null;
      practiceDuration: number;
      sortOrder?: number | null;
      createdOn: string;
      updatedOn: string;
    }>;
    nextToken?: string | null;
  } | null;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnCreateExerciseSubscription = {
  __typename: 'Exercise';
  id: string;
  owner?: string | null;
  title: string;
  exerciseSetId: string;
  description?: string | null;
  metronomeConfig: {
    __typename: 'MetronomeConfig';
    bpm: number;
    timeSignature: {
      __typename: 'TimeSignature';
      unit: number;
      count: number;
    };
  };
  audioFile?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  attachment?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  practiceDuration: number;
  sortOrder?: number | null;
  createdOn: string;
  updatedOn: string;
};

export type OnUpdateExerciseSubscription = {
  __typename: 'Exercise';
  id: string;
  owner?: string | null;
  title: string;
  exerciseSetId: string;
  description?: string | null;
  metronomeConfig: {
    __typename: 'MetronomeConfig';
    bpm: number;
    timeSignature: {
      __typename: 'TimeSignature';
      unit: number;
      count: number;
    };
  };
  audioFile?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  attachment?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  practiceDuration: number;
  sortOrder?: number | null;
  createdOn: string;
  updatedOn: string;
};

export type OnDeleteExerciseSubscription = {
  __typename: 'Exercise';
  id: string;
  owner?: string | null;
  title: string;
  exerciseSetId: string;
  description?: string | null;
  metronomeConfig: {
    __typename: 'MetronomeConfig';
    bpm: number;
    timeSignature: {
      __typename: 'TimeSignature';
      unit: number;
      count: number;
    };
  };
  audioFile?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  attachment?: {
    __typename: 'FileLink';
    id: string;
    name: string;
    size: number;
    url: string;
    contentType?: string | null;
  } | null;
  practiceDuration: number;
  sortOrder?: number | null;
  createdOn: string;
  updatedOn: string;
};

export type OnCreateNotesSubscription = {
  __typename: 'Notes';
  id: string;
  studentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnUpdateNotesSubscription = {
  __typename: 'Notes';
  id: string;
  studentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnDeleteNotesSubscription = {
  __typename: 'Notes';
  id: string;
  studentId: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  owner?: string | null;
};

export type OnCreateAudioRecordingLinkSubscription = {
  __typename: 'AudioRecordingLink';
  id?: string | null;
  createdOn: string;
  updatedOn: string;
  name: string;
  size: number;
  duration: number;
  uploadDone: boolean;
  url?: string | null;
  contentType?: string | null;
  owner?: string | null;
};

export type OnUpdateAudioRecordingLinkSubscription = {
  __typename: 'AudioRecordingLink';
  id?: string | null;
  createdOn: string;
  updatedOn: string;
  name: string;
  size: number;
  duration: number;
  uploadDone: boolean;
  url?: string | null;
  contentType?: string | null;
  owner?: string | null;
};

export type OnDeleteAudioRecordingLinkSubscription = {
  __typename: 'AudioRecordingLink';
  id?: string | null;
  createdOn: string;
  updatedOn: string;
  name: string;
  size: number;
  duration: number;
  uploadDone: boolean;
  url?: string | null;
  contentType?: string | null;
  owner?: string | null;
};

export type ListCompleteExerciseSetsQuery = {
  __typename: 'ModelExerciseSetConnection';
  items: Array<{
    __typename: 'ExerciseSet';
    id: string;
    studentId: string;
    exercisesUnsorted?: {
      __typename: 'ModelExerciseConnection';
      nextToken?: string | null;
      items: Array<{
        __typename: 'Exercise';
        id: string;
        title: string;
        exerciseSetId: string;
        description?: string | null;
        metronomeConfig: {
          __typename: 'MetronomeConfig';
          bpm: number;
          timeSignature: {
            __typename: 'TimeSignature';
            unit: number;
            count: number;
          };
        };
        audioFile?: {
          __typename: 'FileLink';
          id: string;
          name: string;
          size: number;
          url: string;
        } | null;
        attachment?: {
          __typename: 'FileLink';
          id: string;
          url: string;
          name: string;
          size: number;
          contentType?: string | null;
        } | null;
        practiceDuration: number;
        createdOn: string;
        updatedOn: string;
        owner?: string | null;
      }>;
    } | null;
    exercises?: {
      __typename: 'ModelExerciseConnection';
      nextToken?: string | null;
      items: Array<{
        __typename: 'Exercise';
        id: string;
        title: string;
        exerciseSetId: string;
        description?: string | null;
        metronomeConfig: {
          __typename: 'MetronomeConfig';
          bpm: number;
          timeSignature: {
            __typename: 'TimeSignature';
            unit: number;
            count: number;
          };
        };
        audioFile?: {
          __typename: 'FileLink';
          id: string;
          name: string;
          size: number;
          url: string;
        } | null;
        attachment?: {
          __typename: 'FileLink';
          id: string;
          url: string;
          name: string;
          size: number;
          contentType?: string | null;
        } | null;
        practiceDuration: number;
        createdOn: string;
        updatedOn: string;
        owner?: string | null;
      }>;
    } | null;
    createdOn: string;
    updatedOn: string;
    owner?: string | null;
  }>;
  nextToken?: string | null;
};

export type customListStudentsQuery = {
  __typename: 'ModelStudentConnection';
  items: Array<{
    __typename: 'Student';
    id: string;
    name: string;
    email: string;
    additionalEmail?: string | null;
    createdOn: string;
    updatedOn: string;
    owner?: string | null;
  }>;
  nextToken?: string | null;
};

@Injectable({
  providedIn: 'root'
})
export class APIService {
  async CreateStudent(input: CreateStudentInput, condition?: ModelStudentConditionInput): Promise<CreateStudentMutation> {
    const statement = `mutation CreateStudent($input: CreateStudentInput!, $condition: ModelStudentConditionInput) {
        createStudent(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          additionalEmail
          exerciseSets {
            __typename
            items {
              __typename
              id
              studentId
              exercisesUnsorted {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              exercises {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          notes {
            __typename
            items {
              __typename
              id
              studentId
              content
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <CreateStudentMutation>response.data.createStudent;
  }
  async UpdateStudent(input: UpdateStudentInput, condition?: ModelStudentConditionInput): Promise<UpdateStudentMutation> {
    const statement = `mutation UpdateStudent($input: UpdateStudentInput!, $condition: ModelStudentConditionInput) {
        updateStudent(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          additionalEmail
          exerciseSets {
            __typename
            items {
              __typename
              id
              studentId
              exercisesUnsorted {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              exercises {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          notes {
            __typename
            items {
              __typename
              id
              studentId
              content
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <UpdateStudentMutation>response.data.updateStudent;
  }
  async DeleteStudent(input: DeleteStudentInput, condition?: ModelStudentConditionInput): Promise<DeleteStudentMutation> {
    const statement = `mutation DeleteStudent($input: DeleteStudentInput!, $condition: ModelStudentConditionInput) {
        deleteStudent(input: $input, condition: $condition) {
          __typename
          id
          name
          email
          additionalEmail
          exerciseSets {
            __typename
            items {
              __typename
              id
              studentId
              exercisesUnsorted {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              exercises {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          notes {
            __typename
            items {
              __typename
              id
              studentId
              content
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <DeleteStudentMutation>response.data.deleteStudent;
  }
  async CreateExerciseSet(input: CreateExerciseSetInput, condition?: ModelExerciseSetConditionInput): Promise<CreateExerciseSetMutation> {
    const statement = `mutation CreateExerciseSet($input: CreateExerciseSetInput!, $condition: ModelExerciseSetConditionInput) {
        createExerciseSet(input: $input, condition: $condition) {
          __typename
          id
          studentId
          exercisesUnsorted {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          exercises {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <CreateExerciseSetMutation>response.data.createExerciseSet;
  }
  async UpdateExerciseSet(input: UpdateExerciseSetInput, condition?: ModelExerciseSetConditionInput): Promise<UpdateExerciseSetMutation> {
    const statement = `mutation UpdateExerciseSet($input: UpdateExerciseSetInput!, $condition: ModelExerciseSetConditionInput) {
        updateExerciseSet(input: $input, condition: $condition) {
          __typename
          id
          studentId
          exercisesUnsorted {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          exercises {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <UpdateExerciseSetMutation>response.data.updateExerciseSet;
  }
  async DeleteExerciseSet(input: DeleteExerciseSetInput, condition?: ModelExerciseSetConditionInput): Promise<DeleteExerciseSetMutation> {
    const statement = `mutation DeleteExerciseSet($input: DeleteExerciseSetInput!, $condition: ModelExerciseSetConditionInput) {
        deleteExerciseSet(input: $input, condition: $condition) {
          __typename
          id
          studentId
          exercisesUnsorted {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          exercises {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <DeleteExerciseSetMutation>response.data.deleteExerciseSet;
  }
  async CreateExercise(input: CreateExerciseInput, condition?: ModelExerciseConditionInput): Promise<CreateExerciseMutation> {
    const statement = `mutation CreateExercise($input: CreateExerciseInput!, $condition: ModelExerciseConditionInput) {
        createExercise(input: $input, condition: $condition) {
          __typename
          id
          owner
          title
          exerciseSetId
          description
          metronomeConfig {
            __typename
            bpm
            timeSignature {
              __typename
              unit
              count
            }
          }
          audioFile {
            __typename
            id
            name
            size
            url
            contentType
          }
          attachment {
            __typename
            id
            name
            size
            url
            contentType
          }
          practiceDuration
          sortOrder
          createdOn
          updatedOn
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <CreateExerciseMutation>response.data.createExercise;
  }
  async UpdateExercise(input: UpdateExerciseInput, condition?: ModelExerciseConditionInput): Promise<UpdateExerciseMutation> {
    const statement = `mutation UpdateExercise($input: UpdateExerciseInput!, $condition: ModelExerciseConditionInput) {
        updateExercise(input: $input, condition: $condition) {
          __typename
          id
          owner
          title
          exerciseSetId
          description
          metronomeConfig {
            __typename
            bpm
            timeSignature {
              __typename
              unit
              count
            }
          }
          audioFile {
            __typename
            id
            name
            size
            url
            contentType
          }
          attachment {
            __typename
            id
            name
            size
            url
            contentType
          }
          practiceDuration
          sortOrder
          createdOn
          updatedOn
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <UpdateExerciseMutation>response.data.updateExercise;
  }
  async DeleteExercise(input: DeleteExerciseInput, condition?: ModelExerciseConditionInput): Promise<DeleteExerciseMutation> {
    const statement = `mutation DeleteExercise($input: DeleteExerciseInput!, $condition: ModelExerciseConditionInput) {
        deleteExercise(input: $input, condition: $condition) {
          __typename
          id
          owner
          title
          exerciseSetId
          description
          metronomeConfig {
            __typename
            bpm
            timeSignature {
              __typename
              unit
              count
            }
          }
          audioFile {
            __typename
            id
            name
            size
            url
            contentType
          }
          attachment {
            __typename
            id
            name
            size
            url
            contentType
          }
          practiceDuration
          sortOrder
          createdOn
          updatedOn
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <DeleteExerciseMutation>response.data.deleteExercise;
  }
  async CreateNotes(input: CreateNotesInput, condition?: ModelNotesConditionInput): Promise<CreateNotesMutation> {
    const statement = `mutation CreateNotes($input: CreateNotesInput!, $condition: ModelNotesConditionInput) {
        createNotes(input: $input, condition: $condition) {
          __typename
          id
          studentId
          content
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <CreateNotesMutation>response.data.createNotes;
  }
  async UpdateNotes(input: UpdateNotesInput, condition?: ModelNotesConditionInput): Promise<UpdateNotesMutation> {
    const statement = `mutation UpdateNotes($input: UpdateNotesInput!, $condition: ModelNotesConditionInput) {
        updateNotes(input: $input, condition: $condition) {
          __typename
          id
          studentId
          content
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <UpdateNotesMutation>response.data.updateNotes;
  }
  async DeleteNotes(input: DeleteNotesInput, condition?: ModelNotesConditionInput): Promise<DeleteNotesMutation> {
    const statement = `mutation DeleteNotes($input: DeleteNotesInput!, $condition: ModelNotesConditionInput) {
        deleteNotes(input: $input, condition: $condition) {
          __typename
          id
          studentId
          content
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <DeleteNotesMutation>response.data.deleteNotes;
  }
  async CreateAudioRecordingLink(
    input: CreateAudioRecordingLinkInput,
    condition?: ModelAudioRecordingLinkConditionInput
  ): Promise<CreateAudioRecordingLinkMutation> {
    const statement = `mutation CreateAudioRecordingLink($input: CreateAudioRecordingLinkInput!, $condition: ModelAudioRecordingLinkConditionInput) {
        createAudioRecordingLink(input: $input, condition: $condition) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          contentType
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <CreateAudioRecordingLinkMutation>response.data.createAudioRecordingLink;
  }
  async UpdateAudioRecordingLink(
    input: UpdateAudioRecordingLinkInput,
    condition?: ModelAudioRecordingLinkConditionInput
  ): Promise<UpdateAudioRecordingLinkMutation> {
    const statement = `mutation UpdateAudioRecordingLink($input: UpdateAudioRecordingLinkInput!, $condition: ModelAudioRecordingLinkConditionInput) {
        updateAudioRecordingLink(input: $input, condition: $condition) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          contentType
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <UpdateAudioRecordingLinkMutation>response.data.updateAudioRecordingLink;
  }
  async DeleteAudioRecordingLink(
    input: DeleteAudioRecordingLinkInput,
    condition?: ModelAudioRecordingLinkConditionInput
  ): Promise<DeleteAudioRecordingLinkMutation> {
    const statement = `mutation DeleteAudioRecordingLink($input: DeleteAudioRecordingLinkInput!, $condition: ModelAudioRecordingLinkConditionInput) {
        deleteAudioRecordingLink(input: $input, condition: $condition) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          contentType
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <DeleteAudioRecordingLinkMutation>response.data.deleteAudioRecordingLink;
  }
  async GetStudent(id: string): Promise<GetStudentQuery> {
    const statement = `query GetStudent($id: ID!) {
        getStudent(id: $id) {
          __typename
          id
          name
          email
          additionalEmail
          exerciseSets {
            __typename
            items {
              __typename
              id
              studentId
              exercisesUnsorted {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              exercises {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          notes {
            __typename
            items {
              __typename
              id
              studentId
              content
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <GetStudentQuery>response.data.getStudent;
  }
  async ListStudents(filter?: ModelStudentFilterInput, limit?: number, nextToken?: string): Promise<ListStudentsQuery> {
    const statement = `query ListStudents($filter: ModelStudentFilterInput, $limit: Int, $nextToken: String) {
        listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            additionalEmail
            exerciseSets {
              __typename
              items {
                __typename
                id
                studentId
                exercisesUnsorted {
                  __typename
                  items {
                    __typename
                    id
                    owner
                    title
                    exerciseSetId
                    description
                    practiceDuration
                    sortOrder
                    createdOn
                    updatedOn
                  }
                  nextToken
                }
                exercises {
                  __typename
                  items {
                    __typename
                    id
                    owner
                    title
                    exerciseSetId
                    description
                    practiceDuration
                    sortOrder
                    createdOn
                    updatedOn
                  }
                  nextToken
                }
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            notes {
              __typename
              items {
                __typename
                id
                studentId
                content
                createdOn
                updatedOn
                owner
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <ListStudentsQuery>response.data.listStudents;
  }
  async GetExerciseSet(id: string): Promise<GetExerciseSetQuery> {
    const statement = `query GetExerciseSet($id: ID!) {
        getExerciseSet(id: $id) {
          __typename
          id
          studentId
          exercisesUnsorted {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          exercises {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <GetExerciseSetQuery>response.data.getExerciseSet;
  }
  async ListExerciseSets(filter?: ModelExerciseSetFilterInput, limit?: number, nextToken?: string): Promise<ListExerciseSetsQuery> {
    const statement = `query ListExerciseSets($filter: ModelExerciseSetFilterInput, $limit: Int, $nextToken: String) {
        listExerciseSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            studentId
            exercisesUnsorted {
              __typename
              items {
                __typename
                id
                owner
                title
                exerciseSetId
                description
                metronomeConfig {
                  __typename
                  bpm
                  timeSignature {
                    __typename
                    unit
                    count
                  }
                }
                audioFile {
                  __typename
                  id
                  name
                  size
                  url
                  contentType
                }
                attachment {
                  __typename
                  id
                  name
                  size
                  url
                  contentType
                }
                practiceDuration
                sortOrder
                createdOn
                updatedOn
              }
              nextToken
            }
            exercises {
              __typename
              items {
                __typename
                id
                owner
                title
                exerciseSetId
                description
                metronomeConfig {
                  __typename
                  bpm
                  timeSignature {
                    __typename
                    unit
                    count
                  }
                }
                audioFile {
                  __typename
                  id
                  name
                  size
                  url
                  contentType
                }
                attachment {
                  __typename
                  id
                  name
                  size
                  url
                  contentType
                }
                practiceDuration
                sortOrder
                createdOn
                updatedOn
              }
              nextToken
            }
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <ListExerciseSetsQuery>response.data.listExerciseSets;
  }
  async GetExercise(id: string): Promise<GetExerciseQuery> {
    const statement = `query GetExercise($id: ID!) {
        getExercise(id: $id) {
          __typename
          id
          owner
          title
          exerciseSetId
          description
          metronomeConfig {
            __typename
            bpm
            timeSignature {
              __typename
              unit
              count
            }
          }
          audioFile {
            __typename
            id
            name
            size
            url
            contentType
          }
          attachment {
            __typename
            id
            name
            size
            url
            contentType
          }
          practiceDuration
          sortOrder
          createdOn
          updatedOn
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <GetExerciseQuery>response.data.getExercise;
  }
  async ListExercises(filter?: ModelExerciseFilterInput, limit?: number, nextToken?: string): Promise<ListExercisesQuery> {
    const statement = `query ListExercises($filter: ModelExerciseFilterInput, $limit: Int, $nextToken: String) {
        listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            owner
            title
            exerciseSetId
            description
            metronomeConfig {
              __typename
              bpm
              timeSignature {
                __typename
                unit
                count
              }
            }
            audioFile {
              __typename
              id
              name
              size
              url
              contentType
            }
            attachment {
              __typename
              id
              name
              size
              url
              contentType
            }
            practiceDuration
            sortOrder
            createdOn
            updatedOn
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <ListExercisesQuery>response.data.listExercises;
  }
  async GetNotes(id: string): Promise<GetNotesQuery> {
    const statement = `query GetNotes($id: ID!) {
        getNotes(id: $id) {
          __typename
          id
          studentId
          content
          createdOn
          updatedOn
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <GetNotesQuery>response.data.getNotes;
  }
  async ListNotes(filter?: ModelNotesFilterInput, limit?: number, nextToken?: string): Promise<ListNotesQuery> {
    const statement = `query ListNotes($filter: ModelNotesFilterInput, $limit: Int, $nextToken: String) {
        listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            studentId
            content
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <ListNotesQuery>response.data.listNotes;
  }
  async GetAudioRecordingLink(id: string): Promise<GetAudioRecordingLinkQuery> {
    const statement = `query GetAudioRecordingLink($id: ID!) {
        getAudioRecordingLink(id: $id) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          contentType
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <GetAudioRecordingLinkQuery>response.data.getAudioRecordingLink;
  }
  async ListAudioRecordingLinks(
    filter?: ModelAudioRecordingLinkFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAudioRecordingLinksQuery> {
    const statement = `query ListAudioRecordingLinks($filter: ModelAudioRecordingLinkFilterInput, $limit: Int, $nextToken: String) {
        listAudioRecordingLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            createdOn
            updatedOn
            name
            size
            duration
            uploadDone
            url
            contentType
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <ListAudioRecordingLinksQuery>response.data.listAudioRecordingLinks;
  }
  async GetExerciseByUpdatedOn(
    owner?: string,
    updatedOn?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelExerciseFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<GetExerciseByUpdatedOnQuery> {
    const statement = `query GetExerciseByUpdatedOn($owner: String, $updatedOn: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelExerciseFilterInput, $limit: Int, $nextToken: String) {
        getExerciseByUpdatedOn(owner: $owner, updatedOn: $updatedOn, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            owner
            title
            exerciseSetId
            description
            metronomeConfig {
              __typename
              bpm
              timeSignature {
                __typename
                unit
                count
              }
            }
            audioFile {
              __typename
              id
              name
              size
              url
              contentType
            }
            attachment {
              __typename
              id
              name
              size
              url
              contentType
            }
            practiceDuration
            sortOrder
            createdOn
            updatedOn
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (owner) {
      gqlAPIServiceArguments.owner = owner;
    }
    if (updatedOn) {
      gqlAPIServiceArguments.updatedOn = updatedOn;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <GetExerciseByUpdatedOnQuery>response.data.getExerciseByUpdatedOn;
  }
  OnCreateStudentListener: Observable<SubscriptionResponse<OnCreateStudentSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnCreateStudent($owner: String) {
        onCreateStudent(owner: $owner) {
          __typename
          id
          name
          email
          additionalEmail
          exerciseSets {
            __typename
            items {
              __typename
              id
              studentId
              exercisesUnsorted {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              exercises {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          notes {
            __typename
            items {
              __typename
              id
              studentId
              content
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateStudentSubscription>>;

  OnUpdateStudentListener: Observable<SubscriptionResponse<OnUpdateStudentSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateStudent($owner: String) {
        onUpdateStudent(owner: $owner) {
          __typename
          id
          name
          email
          additionalEmail
          exerciseSets {
            __typename
            items {
              __typename
              id
              studentId
              exercisesUnsorted {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              exercises {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          notes {
            __typename
            items {
              __typename
              id
              studentId
              content
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateStudentSubscription>>;

  OnDeleteStudentListener: Observable<SubscriptionResponse<OnDeleteStudentSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteStudent($owner: String) {
        onDeleteStudent(owner: $owner) {
          __typename
          id
          name
          email
          additionalEmail
          exerciseSets {
            __typename
            items {
              __typename
              id
              studentId
              exercisesUnsorted {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              exercises {
                __typename
                items {
                  __typename
                  id
                  owner
                  title
                  exerciseSetId
                  description
                  metronomeConfig {
                    __typename
                    bpm
                  }
                  audioFile {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  attachment {
                    __typename
                    id
                    name
                    size
                    url
                    contentType
                  }
                  practiceDuration
                  sortOrder
                  createdOn
                  updatedOn
                }
                nextToken
              }
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          notes {
            __typename
            items {
              __typename
              id
              studentId
              content
              createdOn
              updatedOn
              owner
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteStudentSubscription>>;

  OnCreateExerciseSetListener: Observable<SubscriptionResponse<OnCreateExerciseSetSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnCreateExerciseSet($owner: String) {
        onCreateExerciseSet(owner: $owner) {
          __typename
          id
          studentId
          exercisesUnsorted {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          exercises {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateExerciseSetSubscription>>;

  OnUpdateExerciseSetListener: Observable<SubscriptionResponse<OnUpdateExerciseSetSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateExerciseSet($owner: String) {
        onUpdateExerciseSet(owner: $owner) {
          __typename
          id
          studentId
          exercisesUnsorted {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          exercises {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateExerciseSetSubscription>>;

  OnDeleteExerciseSetListener: Observable<SubscriptionResponse<OnDeleteExerciseSetSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteExerciseSet($owner: String) {
        onDeleteExerciseSet(owner: $owner) {
          __typename
          id
          studentId
          exercisesUnsorted {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          exercises {
            __typename
            items {
              __typename
              id
              owner
              title
              exerciseSetId
              description
              metronomeConfig {
                __typename
                bpm
                timeSignature {
                  __typename
                  unit
                  count
                }
              }
              audioFile {
                __typename
                id
                name
                size
                url
                contentType
              }
              attachment {
                __typename
                id
                name
                size
                url
                contentType
              }
              practiceDuration
              sortOrder
              createdOn
              updatedOn
            }
            nextToken
          }
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteExerciseSetSubscription>>;

  OnCreateExerciseListener: Observable<SubscriptionResponse<OnCreateExerciseSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnCreateExercise($owner: String) {
        onCreateExercise(owner: $owner) {
          __typename
          id
          owner
          title
          exerciseSetId
          description
          metronomeConfig {
            __typename
            bpm
            timeSignature {
              __typename
              unit
              count
            }
          }
          audioFile {
            __typename
            id
            name
            size
            url
            contentType
          }
          attachment {
            __typename
            id
            name
            size
            url
            contentType
          }
          practiceDuration
          sortOrder
          createdOn
          updatedOn
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateExerciseSubscription>>;

  OnUpdateExerciseListener: Observable<SubscriptionResponse<OnUpdateExerciseSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateExercise($owner: String) {
        onUpdateExercise(owner: $owner) {
          __typename
          id
          owner
          title
          exerciseSetId
          description
          metronomeConfig {
            __typename
            bpm
            timeSignature {
              __typename
              unit
              count
            }
          }
          audioFile {
            __typename
            id
            name
            size
            url
            contentType
          }
          attachment {
            __typename
            id
            name
            size
            url
            contentType
          }
          practiceDuration
          sortOrder
          createdOn
          updatedOn
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateExerciseSubscription>>;

  OnDeleteExerciseListener: Observable<SubscriptionResponse<OnDeleteExerciseSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteExercise($owner: String) {
        onDeleteExercise(owner: $owner) {
          __typename
          id
          owner
          title
          exerciseSetId
          description
          metronomeConfig {
            __typename
            bpm
            timeSignature {
              __typename
              unit
              count
            }
          }
          audioFile {
            __typename
            id
            name
            size
            url
            contentType
          }
          attachment {
            __typename
            id
            name
            size
            url
            contentType
          }
          practiceDuration
          sortOrder
          createdOn
          updatedOn
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteExerciseSubscription>>;

  OnCreateNotesListener: Observable<SubscriptionResponse<OnCreateNotesSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnCreateNotes($owner: String) {
        onCreateNotes(owner: $owner) {
          __typename
          id
          studentId
          content
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateNotesSubscription>>;

  OnUpdateNotesListener: Observable<SubscriptionResponse<OnUpdateNotesSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateNotes($owner: String) {
        onUpdateNotes(owner: $owner) {
          __typename
          id
          studentId
          content
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateNotesSubscription>>;

  OnDeleteNotesListener: Observable<SubscriptionResponse<OnDeleteNotesSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteNotes($owner: String) {
        onDeleteNotes(owner: $owner) {
          __typename
          id
          studentId
          content
          createdOn
          updatedOn
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteNotesSubscription>>;

  OnCreateAudioRecordingLinkListener: Observable<SubscriptionResponse<OnCreateAudioRecordingLinkSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnCreateAudioRecordingLink($owner: String) {
        onCreateAudioRecordingLink(owner: $owner) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          contentType
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateAudioRecordingLinkSubscription>>;

  OnUpdateAudioRecordingLinkListener: Observable<SubscriptionResponse<OnUpdateAudioRecordingLinkSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAudioRecordingLink($owner: String) {
        onUpdateAudioRecordingLink(owner: $owner) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          contentType
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateAudioRecordingLinkSubscription>>;

  OnDeleteAudioRecordingLinkListener: Observable<SubscriptionResponse<OnDeleteAudioRecordingLinkSubscription>> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAudioRecordingLink($owner: String) {
        onDeleteAudioRecordingLink(owner: $owner) {
          __typename
          id
          createdOn
          updatedOn
          name
          size
          duration
          uploadDone
          url
          contentType
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteAudioRecordingLinkSubscription>>;

  async ListCompleteExerciseSets(
    filter?: ModelExerciseSetFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCompleteExerciseSetsQuery> {
    const statement = `query ListCompleteExerciseSets($filter: ModelExerciseSetFilterInput, $limit: Int, $nextToken: String) {
        listExerciseSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            studentId
            exercisesUnsorted {
              __typename
              nextToken
              items {
                __typename
                id
                title
                exerciseSetId
                description
                metronomeConfig {
                  __typename
                  bpm
                  timeSignature {
                    __typename
                    unit
                    count
                  }
                }
                audioFile {
                  __typename
                  id
                  name
                  size
                  url
                }
                attachment {
                  __typename
                  id
                  url
                  name
                  size
                  contentType
                }
                practiceDuration
                createdOn
                updatedOn
                owner
              }
            }
            exercises {
              __typename
              nextToken
              items {
                __typename
                id
                title
                exerciseSetId
                description
                metronomeConfig {
                  __typename
                  bpm
                  timeSignature {
                    __typename
                    unit
                    count
                  }
                }
                audioFile {
                  __typename
                  id
                  name
                  size
                  url
                }
                attachment {
                  __typename
                  id
                  url
                  name
                  size
                  contentType
                }
                practiceDuration
                createdOn
                updatedOn
                owner
              }
            }
            createdOn
            updatedOn
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <ListCompleteExerciseSetsQuery>response.data.listExerciseSets;
  }
  async customListStudents(filter?: ModelStudentFilterInput, limit?: number, nextToken?: string): Promise<customListStudentsQuery> {
    const statement = `query customListStudents($filter: ModelStudentFilterInput, $limit: Int, $nextToken: String) {
        listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            email
            additionalEmail
            createdOn
            updatedOn
            owner
            sortOrder
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(graphqlOperation(statement, gqlAPIServiceArguments))) as any;
    return <customListStudentsQuery>response.data.listStudents;
  }
}

import { Injectable, NgZone } from '@angular/core';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ExerciseArchiveFacadeService } from '../store/exercises-archive/exercise-archive-facade.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { StudentFacadeService } from '../store/student/student-facade.service';
import { uuid4 } from '@capacitor/core/dist/esm/util';
import { Router } from '@angular/router';

const GUIDE_CREATE_STUDENT_FAST = 38736;
const CHECKLIST_ID = 12883;

@Injectable({ providedIn: 'root' })
export class UserguidingService {
  constructor(
    private authService: AuthService,
    private exerciseArchiveFacadeService: ExerciseArchiveFacadeService,
    private analyticsService: AnalyticsService,
    private studentFacadeService: StudentFacadeService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  init(): void {
    this.authService.currentUser$
      .pipe(
        filter(user => !!user?.username),
        distinctUntilChanged((a, b) => a.username === b.username)
      )
      .subscribe(user => {
        this.exerciseArchiveFacadeService.fetchFirstPage(1);
        if ((window as any).userGuiding) {
          (window as any).userGuiding.identify(user.username, {
            name: user.name
          });
        }
      });

    if ((window as any).userGuidingLayer?.push) {
      (window as any).userGuidingLayer.push({
        event: 'onPreviewStep',
        fn: data =>
          this.ngZone.run(() => {
            this.handleStepEvent(data.guideId, data.stepIndex);
            this.analyticsService.track('UserGuiding step', data);
          })
      });
    }
  }

  private handleStepEvent(guideId: number, stepIndex: number): void {
    if (guideId === GUIDE_CREATE_STUDENT_FAST && stepIndex === 2) {
      this.authService.currentUser$.pipe(take(1)).subscribe(user =>
        this.studentFacadeService.createStudent({
          name: user.name,
          email: user.email,
          id: uuid4(),
          createdOn: new Date(),
          updatedOn: new Date()
        })
      );
      this.router.navigate(['/']);
    }
  }

  private launchChecklist(checlistId: number, itemsShown) {
    if ((window as any).userGuiding) {
      // we need to wait until identification is over...
      setTimeout(() => {
        (window as any).userGuiding.launchChecklist(checlistId, { itemsShown });
      }, 2000);
    }
  }

  setStudentsCount(students: number): void {
    this.setStudentsCountForUserGuiding(students);
    if (!(students > 0)) {
      this.launchChecklist(CHECKLIST_ID, true);
    }
  }

  setExercisesCount(exercises: number): void {
    this.setExercisesCountForUserGuiding(exercises);
    if (!(exercises > 0)) {
      this.launchChecklist(CHECKLIST_ID, true);
    }
  }

  private setStudentsCountForUserGuiding(students: number): void {
    localStorage.setItem('trivs_students_count', `${students}`);
  }

  private setExercisesCountForUserGuiding(exercises: number): void {
    localStorage.setItem('trivs_exercises_count', `${exercises}`);
  }
}

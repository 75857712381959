import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

console.log(environment);

if (environment.sentry) {
  Sentry.init({
    dsn: 'https://cf335b9b103c4b7cb3e8c9492f9c84bd@o495439.ingest.sentry.io/5590641',
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [environment.sentry.tracingOrigin],
        routingInstrumentation: Sentry.routingInstrumentation
      })
    ],
    environment: environment.sentry.environment,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0
  });
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

import { AuthGuardService } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'starter',
    loadChildren: () => import('./starter/starter.module').then(m => m.StarterPageModule)
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'students',
        loadChildren: () => import('./students/students.module').then(m => m.StudentsPageModule)
      },
      {
        path: 'student',
        loadChildren: () => import('./student/student.module').then(m => m.StudentPageModule)
      },

      {
        path: 'add-student',
        loadChildren: () => import('./add-student/add-student.module').then(m => m.AddStudentPageModule)
      },
      {
        path: 'edit-student/:id',
        loadChildren: () => import('./edit-student/edit-student.module').then(m => m.EditStudentPageModule)
      },
      {
        path: 'recordings',
        loadChildren: () => import('./recordings/recordings.module').then(m => m.RecordingsPageModule)
      },
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'students'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'corrected' })],
  providers: [],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { State } from '../index';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adapter, StudentState, studentsFeatureKey } from './student.reducer';

export interface StudentFeatureState extends State {
  [studentsFeatureKey]: StudentState;
}

const studentFeatureSelector = createFeatureSelector<StudentFeatureState, StudentState>(studentsFeatureKey);

export const {
  selectIds: selectIds,
  selectEntities: selectStudentEntities,
  selectAll: selectStudentAll,
  selectTotal: selectStudentTotal
} = adapter.getSelectors(studentFeatureSelector);

export const selectStudentById = (studentId: string) =>
  createSelector(studentFeatureSelector, state => {
    return state.entities[studentId];
  });

import { EnvironmentType } from './environment.type';

export const environment: EnvironmentType = {
  sentry: {
    environment: 'production',
    tracingOrigin: 'coach.trivs.app'
  },
  enableStoreDevTools: false,
  enableAnalytics: true
};

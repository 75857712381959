import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';

@Injectable()
export class AnalyticsEffects {
  captureAllActions$ = createEffect(
    () => {
      return this.actions$.pipe(tap(action => this.analyticsService.track(action.type)));
    },
    { dispatch: false }
  );

  constructor(private actions$: Actions, private analyticsService: AnalyticsService) {}
}

import { createAction, props } from '@ngrx/store';
import { Notes } from '../../../model/notes';

export const addManyStudentNotes = createAction('[StudentNotes/API] Add many StudentNotes', props<{ notes: Notes[] }>());

export const addStudentNotes = createAction('[StudentNotes/API] Add StudentNotes', props<{ notes: Notes }>());

export const clearStudentNotes = createAction('[StudentNotes/API] Clear StudentNotes');

// ----- Backend ----
export const fetchStudentNotes = createAction('[StudentNotes/API] Fetch StudentNotes', props<{ studentId: string }>());

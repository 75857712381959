import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { defer, Observable } from 'rxjs';
import { shareReplay, switchMap, take } from 'rxjs/operators';
import { Student } from '../../model/student';
import { selectStudentId, State } from '../index';
import { addStudent, fetchStudents, updateStudent } from './student.actions';
import { selectStudentAll, selectStudentById } from './student.selector';

@Injectable({ providedIn: 'root' })
export class StudentFacadeService {
  students$: Observable<Student[]> = defer(() => {
    this.store$.dispatch(fetchStudents());
    return this.store$.select(selectStudentAll);
  }).pipe(shareReplay({ bufferSize: 1, refCount: false }));

  activeStudent$ = defer(() => {
    this.store$.dispatch(fetchStudents());
    return this.store$.select(selectStudentId).pipe(switchMap(studentId => this.store$.select(selectStudentById(studentId))));
  }).pipe(shareReplay({ bufferSize: 1, refCount: false }));

  constructor(private store$: Store<State>) {}

  createStudent(student: Student): void {
    this.store$.dispatch(addStudent({ student }));
  }

  updateStudent(student: Student): void {
    this.store$.dispatch(updateStudent({ student }));
  }

  reorderStudents(fromIndex: number, toIndex: number): void {
    this.students$.pipe(take(1)).subscribe(students => {
      const studentsCopy = JSON.parse(JSON.stringify(students));
      const student = studentsCopy[fromIndex];
      studentsCopy.splice(fromIndex, 1);
      studentsCopy.splice(toIndex, 0, student);

      studentsCopy.forEach((student, index) => {
        student.sortOrder = index;
        this.updateStudent(student);
      });
    });
  }
}

import mixpanel from 'mixpanel-browser';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';

import { API } from 'aws-amplify';
import { ReplaySubject } from 'rxjs';

const MIXPANEL_TOKEN = '2722fd68e09c18c0b2fbabba4b8cc11a';
const UUID_REGEX = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/;

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private initialized = false;
  private analyticsEnabled$ = new ReplaySubject<boolean>(1);

  constructor(private router: Router, private authService: AuthService) {}

  init(): void {
    if (!this.initialized && environment.enableAnalytics) {
      API.endpoint('userjourney').then(mixpanelProxyEndpoint => {
        mixpanel.init(MIXPANEL_TOKEN, { api_host: mixpanelProxyEndpoint });
        // Userguiding expect mixpanel on window....
        (window as any).mixpanel = mixpanel;
        this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((event: NavigationEnd) =>
            this.track(`navigate ${event.urlAfterRedirects.replace(/\?.*/, '').replace(UUID_REGEX, '').replace(/\/$/, '')}`)
          );
        this.authService.currentUser$.subscribe(user => this.setIdentity(user));
        this.initialized = true;
        this.analyticsEnabled$.next(true);
      });
    } else {
      this.analyticsEnabled$.next(environment.enableAnalytics);
    }
  }

  setIdentity(user: { email: string }) {
    this.analyticsEnabled$.pipe(take(1)).subscribe(analyticsEnabled => {
      if (analyticsEnabled) {
        if (user?.email) {
          mixpanel.identify(user.email);
          mixpanel.people.set({ $email: user.email });
        } else {
          this.track('setIdentity: email was not set');
        }
      }
    });
  }

  track(eventName: string, properties?: { [key: string]: any }): void {
    this.analyticsEnabled$.pipe(take(1)).subscribe(analyticsEnabled => {
      if (analyticsEnabled) {
        this.init();
        mixpanel.track(eventName, properties);
      } else {
        console.log(`analytics: ${eventName} ${JSON.stringify(properties)}`);
      }
    });
  }
}

import { ActionReducerMap, createFeatureSelector, createSelector, MetaReducer } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from '../route-serializer';

export interface State {
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<any> = { router: routerReducer };
export const metaReducers: MetaReducer<State>[] = [];

export const routerFeatureSelector = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectRouteParam = (paramName: string) =>
  createSelector(routerFeatureSelector, routerState => routerState.state.params[paramName]);

export const selectQueryParam = (paramName: string) =>
  createSelector(routerFeatureSelector, routerState => routerState.state.queryParams[paramName]);

// TODO rename param
export const selectStudentId = createSelector(routerFeatureSelector, routerState => routerState.state.params.studentId);

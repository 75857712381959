import { createAction, props } from '@ngrx/store';
import { ExerciseSet } from '../../../model/exercise-set';

export const addStudentExerciseSets = createAction(
  '[StudentExerciseSet/API] Add multiple StudentExerciseSets',
  props<{ exerciseSet: ExerciseSet[] }>()
);

export const addStudentExerciseSet = createAction('[StudentExerciseSet/API] Add StudentExerciseSet', props<{ exerciseSet: ExerciseSet }>());

export const clearStudentExerciseSets = createAction('[StudentExerciseSet/API] Clear StudentExerciseSets');

// ----- Backend ----
export const fetchStudentExerciseSets = createAction('[StudentExerciseSet/API] Fetch StudentExerciseSets', props<{ studentId: string }>());
